/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Container, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Copyright from '../Copyright/Copyright';

import reporteResultados from '../../Core/Api/reporteResultados';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

function createData(_data) {
  return {
    candidato: _data.num_cedula,
    nombre: _data.des_nombre_can,
    votos: _data.can_votos,
    cod_papeleta: _data.cod_papeleta,
  };
}

const EVPresidenteSector = props => {
  const { classes } = props;

  // eslint-disable-next-line no-unused-vars
  const [showResults, setShowResults] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  // INFORMACION DEL DETALLE DE LA PAPELETA
  const [rowSelected, setRowSelected] = useState({
    cedula: '',
    propietario: '',
  });

  const [dataVotosByJRV, setDataVotosByJRV] = useState([]);
  const [isDataEmpty, setDataEmpty] = useState(false);

  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', '', ''],
  ]);
  const [currentDate, setCurrentDate] = useState('');

  const [openPapeleta, setOpenPapeleta] = useState(false);
  const handleClose = () => {
    setOpenPapeleta(false);
  };

  const heads = [
    {
      name: 'Cédula',
    },
    {
      name: 'Candidato(a)',
    },
    {
      name: 'Votos Recibidos',
    },
  ];

  const getTimeStamp = someDate => {
    const now = new Date(someDate);
    return `${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }`;
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  const formatDate = date => {
    const dateParts = date.split('/');
    const month = dateParts[1];
    const day = dateParts[0];
    const year = dateParts[2];

    return [day, month, year].join('/');
  };

  useEffect(() => {
    async function fetchListAPI() {
      await reporteResultados
        .getReporteResultados006()
        .then(result => {
          setIsLoading(false);
          const info = Lodash.get(
            result,
            ['data', 'response'],
            [],
          );
          const currDate = Lodash.get(
            result,
            ['data', 'currentDate'],
            [],
          );

          const cDate = formatDate(
            new Date(currDate).toLocaleDateString(
              'es-ES',
              options,
            ),
          );
          const cHora = getTimeStamp(currDate);

          if (info instanceof Object && info.length > 0) {
            const rowItems = info.map(row => {
              return createData(row);
            });
            setFilteredItems(rowItems);
            setCurrentDate(
              `Fecha: ${cDate} | Hora: ${cHora}`,
            );
            setDataEmpty(false);
          } else {
            setFilteredItems([]);
            setDataEmpty(true);
          }
        })
        .catch(_error => {
          // eslint-disable-next-line no-console
          console.log(_error);
        });
    }

    fetchListAPI();
  }, []);

  const consultar = (cedula, propietario, codPapeleta) => {
    const newRow = {
      ...rowSelected,
      cedula,
      propietario,
    };

    reporteResultados
      .getDetalleVotacionPresidente(codPapeleta)
      .then(result => {
        setRowSelected(newRow);
        const votosByJRV = Lodash.get(
          result,
          ['data', 'response', 'votosByJRV'],
          [],
        );
        const currDate = Lodash.get(
          result,
          ['data', 'currentDate'],
          [],
        );

        const cDate = formatDate(
          new Date(currDate).toLocaleDateString(
            'es-ES',
            options,
          ),
        );
        const cHora = getTimeStamp(currDate);

        setDataVotosByJRV(votosByJRV);
        setCurrentDate(`Fecha: ${cDate} | Hora: ${cHora}`);
        setOpenPapeleta(true);
      });
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    const actionConsultar = {
      aria: 'Consultar',
      key: 'consultar',
      showAction: true,
      icon: (
        <VisibilityIcon
          fontSize="default"
          style={{ color: '#008945' }}
        />
      ),
      clickHandler: _item => {
        consultar(
          _item.candidato,
          _item.nombre,
          _item.cod_papeleta,
        );
      },
    };

    actions.push(actionConsultar);

    return actions;
  };

  const renderTable = () => {
    const list = dataVotosByJRV;
    let total = 0;
    for (let i = 0; i < list.length; i++) {
      total += list[i].can_votos;
    }
    return (
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>JRV</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Votos Obtenidos</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(l => (
              <TableRow key={`votoBy-${l.cod_junta}`}>
                <TableCell align="center">
                  {l.cod_junta}
                </TableCell>
                <TableCell align="center">
                  {l.can_votos}
                </TableCell>
              </TableRow>
            ))}
            <TableRow style={{ backgroundColor: '#eee' }}>
              <TableCell align="center">
                <strong>Total</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{total}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h3 style={{ textAlign: 'center' }}>
        REPORTE DE RESULTADOS
      </h3>
      <h4
        className={classes.subtitle}
        style={{ textAlign: 'center' }}
      >
        Papeletas Presidente de Sectores
      </h4>

      <h4
        style={{
          textAlign: 'center',
          color: '#008945',
          marginTop: 40,
          fontSize: 18,
        }}
      >
        {currentDate}
      </h4>

      <MacroList
        dataEmpty={isDataEmpty}
        listName=""
        uiStylesClasses={classes}
        btnConfig={{
          name: '+ Registro',
          event: () =>
            (window.location.href = `/presidente/add`),
        }}
        actionConfig={setActions}
        tableHeads={heads}
        tableBody={filteredItems}
        displayCopyright={false}
        isCrudAvailable={false}
      />

      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="lg"
        open={openPapeleta}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Button
          onClick={() => {
            window.print();
          }}
          variant="contained"
          color="primary"
          className={classes.btnPrint}
        >
          Imprimir
        </Button>
        <DialogTitle
          id="alert-dialog-slide-title"
          color="warning"
          style={{ textAlign: 'center' }}
        >
          <span style={{ color: '#008945' }}>
            <strong>
              Detalle de Presidencia de Organización
              Sectorial
            </strong>
          </span>
          <p style={{ fontSize: 14 }}>{currentDate}</p>
        </DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Cédula</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Nombre del Candidato(a)</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  {rowSelected.cedula}
                </TableCell>
                <TableCell align="center">
                  {rowSelected.propietario}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {renderTable()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            className={classes.btnActions}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

EVPresidenteSector.propTypes = {};

export default withStyles(style)(EVPresidenteSector);
