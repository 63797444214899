/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { PlnDropdown } from '../../Components/Input';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Copyright from '../Copyright/Copyright';
import Consts from '../../Core/Consts/Consts';
import {
  useProvincias,
  getCantonsByProvince,
  getDistritoBy,
} from '../../Core/Api/locationApi';

import adjudicacionPuestos from '../../Core/Api/adjudicacionPuestos';

function createData(_data) {
  const filterPuesto = Consts.puestosDelegadosDistritales.filter(
    status => {
      return status.codigo === _data.num_elegido;
    },
  );
  return {
    province: _data.des_nombre_p,
    canton: _data.des_nombre_c,
    distrito: _data.des_nombre_ad,
    puesto: filterPuesto[0].descripcion,
    cedula: _data.num_cedula,
    nombre: _data.des_nombre,
  };
}

const APDelegadosDistritales = props => {
  const { classes } = props;
  const { data } = useProvincias();

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState(9);
  const [sCanton, setSCanton] = useState(999);
  // eslint-disable-next-line no-unused-vars
  const [sDistrito, setSDistrito] = useState(999);

  // INFORMACION DEL DETALLE DE LA PAPELETA

  const [isDataEmpty, setDataEmpty] = useState(false);
  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', ''],
  ]);
  const [currentDate, setCurrentDate] = useState('');

  const heads = [
    {
      name: 'Provincia',
    },
    {
      name: 'Cantón',
    },
    {
      name: 'Distrito',
    },
    {
      name: 'Puesto',
    },
    {
      name: 'Cédula',
    },
    {
      name: 'Nombre',
    },
  ];

  // Lista de: Provincias, Cantones, Distritos y Movimientos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      canton: 999,
      distrito: 999,
      numero: '',
    }),
  );

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN-', value: 999 },
  ]);
  const [distritos, setDistritos] = useState([
    { itemLabel: '- SELECCIONE EL DISTRITO-', value: 999 },
  ]);

  const getTimeStamp = someDate => {
    const now = new Date(someDate);
    return `${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }`;
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  const formatDate = date => {
    const dateParts = date.split('/');
    const month = dateParts[1];
    const day = dateParts[0];
    const year = dateParts[2];

    return [day, month, year].join('/');
  };

  const validationSchema = Yup.object({});

  const setProvincia = p => {
    setCantones([
      {
        itemLabel: '- SELECCIONE EL CANTÓN-',
        value: 999,
      },
    ]);
    setDistritos([
      {
        itemLabel: '- SELECCIONE EL DISTRITO-',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.canton = 999;
    initialValues.distrito = 999;
    if (p !== 9) {
      getCantonsByProvince(p).then(result => {
        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN-',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );
          setCantones(clist);
        }
      });
    }
  };

  const setCanton = c => {
    initialValues.distrito = 999;
    initialValues.provincia = sProvincia.value;
    initialValues.canton = c;
    initialValues.movimiento = 9;

    if (c !== 999) {
      getDistritoBy(sProvincia.value, c).then(result => {
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);
          const dlist = distritosList.map(
            ({ cod_dist_ad, des_nombre_ad }) => {
              return {
                itemLabel: des_nombre_ad,
                value: cod_dist_ad,
              };
            },
          );
          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });
    } else {
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
    }
  };

  const setDistrito = d => {
    initialValues.provincia = sProvincia.value;
    initialValues.canton = sCanton.value;
    initialValues.distrito = d;
    if (d !== 999) {
      setSDistrito(
        Lodash.find(distritos, {
          value: d,
        }),
      );
    } else {
      setSDistrito({ itemLabel: '', value: 0 });
    }
  };

  const doSearch = values => () => {
    setShowResults(true);
    setIsLoading(true);
    const { canton, distrito, provincia } = values;
    const codProvincia = provincia === 9 ? -1 : provincia;
    const codCanton = canton === 999 ? -1 : canton;
    const codDistrito = distrito === 999 ? -1 : distrito;
    adjudicacionPuestos
      .getReporteResultados001(
        codProvincia,
        codCanton,
        codDistrito,
      )
      .then(result => {
        setIsLoading(false);
        const info = Lodash.get(
          result,
          ['data', 'response'],
          [],
        );
        const currDate = Lodash.get(
          result,
          ['data', 'currentDate'],
          [],
        );

        const cDate = formatDate(
          new Date(currDate).toLocaleDateString(
            'es-ES',
            options,
          ),
        );
        const cHora = getTimeStamp(currDate);

        if (info instanceof Object && info.length > 0) {
          const rowItems = info.map(row => {
            return createData(row);
          });
          setFilteredItems(rowItems);
          setCurrentDate(
            `Fecha: ${cDate} | Hora: ${cHora}`,
          );
          setDataEmpty(false);
        } else {
          setFilteredItems([]);
          setDataEmpty(true);
        }
      });
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <h4>Provincia</h4>
            <Field
              name="provincia"
              data={provincias}
              component={PlnDropdown}
              onChange={provincia =>
                setProvincia(provincia)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <h4>Cantón</h4>
            <Field
              name="canton"
              data={cantones}
              component={PlnDropdown}
              onChange={canton => setCanton(canton)}
            />
          </Grid>
          <Grid item xs={4}>
            <h4>Distrito Administrativo</h4>
            <Field
              name="distrito"
              data={distritos}
              component={PlnDropdown}
              onChange={distrito => setDistrito(distrito)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    return actions;
  };

  if (!Lodash.isEmpty(provincias)) {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <h3>ADJUDICACIÓN DE PUESTOS</h3>
        <h4 className={classes.subtitle}>
          Delegados Distritales y Comité Ejecutivo Distrital
        </h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formik => (
            <>
              {renderForm()}
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={doSearch(formik.values)}
                    style={{ marginTop: 10 }}
                  >
                    BUSCAR
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>

        {showResults &&
          (!isLoading ? (
            <>
              <MacroList
                dataEmpty={isDataEmpty}
                listName={currentDate}
                uiStylesClasses={classes}
                btnConfig={{
                  name: '+ Registro',
                  event: () =>
                    (window.location.href = `/distritales/add`),
                }}
                actionConfig={setActions}
                tableHeads={heads}
                tableBody={filteredItems}
                displayCopyright={false}
                isCrudAvailable={false}
              />
            </>
          ) : (
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center' }}
              >
                <CircularProgress
                  style={{
                    width: '28px',
                    height: '24px',
                  }}
                />
              </Grid>
            </Grid>
          ))}
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    );
  }
  return '';
};

APDelegadosDistritales.propTypes = {};

export default withStyles(style)(APDelegadosDistritales);
