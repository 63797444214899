/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { PlnDropdown } from '../../Components/Input';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Copyright from '../Copyright/Copyright';

import { useSectores } from '../../Core/Api/locationApi';

import adjudicacionPuestos from '../../Core/Api/adjudicacionPuestos';

function createData(_data) {
  return {
    sector: _data.des,
    puesto: _data.num_elegido,
    cedula: _data.num_cedula,
    nombre: _data.des_nombre,
  };
}

const APSectores = props => {
  const { classes } = props;
  const listSectores = useSectores();

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Opciones Seleccionadas
  const sectores = Lodash.get(
    listSectores,
    ['data', 'frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE EL SECTOR -',
        value: 9,
      },
    ],
  );

  const [isDataEmpty, setDataEmpty] = useState(false);

  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', '', ''],
  ]);
  const [currentDate, setCurrentDate] = useState('');

  const heads = [
    {
      name: 'Sector',
    },
    {
      name: 'Puesto',
    },
    {
      name: 'Cédula',
    },
    {
      name: 'Nombre',
    },
  ];

  // Lista de: Sector y Numero
  const [initialValues] = useState({
    sector: 9,
    numero: '',
  });

  const getTimeStamp = someDate => {
    const now = new Date(someDate);
    return `${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }`;
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  const formatDate = date => {
    const dateParts = date.split('/');
    const month = dateParts[1];
    const day = dateParts[0];
    const year = dateParts[2];

    return [day, month, year].join('/');
  };

  const validationSchema = Yup.object({});

  const setSector = d => {
    initialValues.sector = d;
  };

  const doSearch = values => () => {
    setShowResults(true);
    setIsLoading(true);
    const { sector } = values;
    const codSector = sector === 9 ? -1 : sector;

    adjudicacionPuestos
      .getReporteResultados006(codSector)
      .then(result => {
        setIsLoading(false);
        const info = Lodash.get(
          result,
          ['data', 'response'],
          [],
        );
        const currDate = Lodash.get(
          result,
          ['data', 'currentDate'],
          [],
        );

        const cDate = formatDate(
          new Date(currDate).toLocaleDateString(
            'es-ES',
            options,
          ),
        );
        const cHora = getTimeStamp(currDate);

        if (info instanceof Object && info.length > 0) {
          const rowItems = info.map(row => {
            return createData(row);
          });
          setFilteredItems(rowItems);
          setCurrentDate(
            `Fecha: ${cDate} | Hora: ${cHora}`,
          );
          setDataEmpty(false);
        } else {
          setFilteredItems([]);
          setDataEmpty(true);
        }
      });
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h4>Sector</h4>
            <Field
              name="sector"
              data={sectores}
              component={PlnDropdown}
              onChange={sector => setSector(sector)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    return actions;
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h3>ADJUDICACIÓN DE PUESTOS</h3>
      <h4 className={classes.subtitle}>
        Representantes al Órgano Consultivo Nacional
        (Sectores)
      </h4>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {formik => (
          <>
            {renderForm()}
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'right' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={doSearch(formik.values)}
                  style={{ marginTop: 10 }}
                >
                  BUSCAR
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>

      {showResults &&
        (!isLoading ? (
          <MacroList
            dataEmpty={isDataEmpty}
            listName={currentDate}
            uiStylesClasses={classes}
            btnConfig={{
              name: '+ Registro',
              event: () =>
                (window.location.href = `/sectorial/add`),
            }}
            actionConfig={setActions}
            tableHeads={heads}
            tableBody={filteredItems}
            displayCopyright={false}
            isCrudAvailable={false}
          />
        ) : (
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{ textAlign: 'center' }}
            >
              <CircularProgress
                style={{
                  width: '28px',
                  height: '24px',
                }}
              />
            </Grid>
          </Grid>
        ))}

      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

APSectores.propTypes = {};

export default withStyles(style)(APSectores);
