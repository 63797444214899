/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useJwt } from 'react-jwt';
import * as Yup from 'yup';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import Box from '@material-ui/core/Box';
import { PlnDropdown } from '../../Components/Input';
import Copyright from '../Copyright/Copyright';
import Storage from '../../Storage/Storage';
import Consts from '../../Core/Consts/Consts';
import miembrosMesaApi from '../../Core/Api/miembrosMesaApi';
import {
  useProvincias,
  getCantonsByProvince,
  getDistritoByTwo,
} from '../../Core/Api/locationApi';

import { generateGafete } from '../../Core/Utils/Comprobante';

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const GafetesMiembrosMesa = () => {
  const classes = useStyles();
  const { data } = useProvincias();
  const tokenStorage = Storage.instance;
  const { decodedToken } = useJwt(tokenStorage.getToken());

  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState(9);
  const [sCanton, setSCanton] = useState(999);
  // eslint-disable-next-line no-unused-vars
  const [sDistrito, setSDistrito] = useState(999);

  // Lista de: Provincias, Cantones, Distritos y Movimientos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      canton: 999,
      distrito: 999,
      jrv: 999,
    }),
  );

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN-', value: 999 },
  ]);
  const [distritos, setDistritos] = useState([
    { itemLabel: '- SELECCIONE EL DISTRITO-', value: 999 },
  ]);
  const [jrv, setJRV] = useState([
    { itemLabel: '- SELECCIONE LA JRV -', value: 999 },
  ]);

  const validationSchema = Yup.object({});

  const setProvincia = p => {
    setCantones([
      {
        itemLabel: '- SELECCIONE EL CANTÓN -',
        value: 999,
      },
    ]);
    setDistritos([
      {
        itemLabel: '- SELECCIONE EL DISTRITO -',
        value: 999,
      },
    ]);
    setJRV([
      {
        itemLabel: '- SELECCIONE LA JRV -',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.canton = 999;
    initialValues.distrito = 999;
    initialValues.jrv = 999;
    if (p !== 9) {
      getCantonsByProvince(p).then(result => {
        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN -',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );
          setCantones(clist);
        }
      });
    }
  };

  const setCanton = c => {
    initialValues.distrito = 999;
    initialValues.provincia = sProvincia.value;
    initialValues.canton = c;
    initialValues.jrv = 999;

    if (c !== 999) {
      getDistritoByTwo(sProvincia.value, c).then(result => {
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);
          const dlist = distritosList.map(
            ({ cod_distrito, des_nombre_d }) => {
              return {
                itemLabel: des_nombre_d,
                value: cod_distrito,
              };
            },
          );
          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });
    } else {
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
    }
  };

  const getJRV = async _codDistr => {
    const currentDistr = parseInt(_codDistr, 10);

    if (currentDistr > 0 && currentDistr !== 999) {
      // const { codProvincia, codCanton } = memberInfo;

      await miembrosMesaApi
        .getJuntaReceptora(
          sProvincia.value,
          sCanton.value,
          currentDistr,
        )
        .then(_res => {
          const listJRV = _res.data.response;
          if (listJRV instanceof Array) {
            const jrvlist = listJRV.map(
              // eslint-disable-next-line no-unused-vars
              ({ num_jvirt, index }) => {
                return {
                  itemLabel: `Nº ${num_jvirt}`,
                  value: num_jvirt,
                };
              },
            );
            jrvlist.unshift({
              itemLabel: '- SELECCIONE LA JRV -',
              value: 999,
            });

            setJRV(jrvlist);
          }
        });
    }
  };

  const getJRVSelected = _jrv => {
    const currentJRV = parseInt(_jrv, 10);

    if (currentJRV > 0 && currentJRV !== 999) {
      // console.log(currentJRV);
    }
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <h4>Provincia</h4>
            <Field
              name="provincia"
              data={provincias}
              component={PlnDropdown}
              onChange={provincia =>
                setProvincia(provincia)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <h4>Cantón</h4>
            <Field
              name="canton"
              data={cantones}
              component={PlnDropdown}
              onChange={canton => setCanton(canton)}
            />
          </Grid>
          <Grid item xs={3}>
            <h4>Distrito </h4>
            <Field
              name="distrito"
              data={distritos}
              component={PlnDropdown}
              onChange={dist => {
                getJRV(dist);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <h4>JRV</h4>
            <Field
              name="jrv"
              data={jrv}
              component={PlnDropdown}
              onChange={jrvItem => getJRVSelected(jrvItem)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const generateGafetes = values => async () => {
    let itemsList = [];
    // eslint-disable-next-line no-shadow
    const { provincia, canton, distrito, jrv } = values;
    const codProvincia = provincia === 9 ? -1 : provincia;
    const codCanton = canton === 999 ? -1 : canton;
    const codDistrito = distrito === 999 ? -1 : distrito;
    const codJrv = jrv === 999 ? -1 : jrv;

    try {
      if (decodedToken) {
        const info = await miembrosMesaApi.getAllMiembrosMesaFiltered(
          decodedToken.num_cedula,
          codProvincia,
          codCanton,
          codDistrito,
          codJrv,
        );

        const dataM = info.data.response;

        if (dataM instanceof Array && info.status === 200) {
          itemsList = dataM.map(member => {
            return {
              provincia: member.des_nombre_p,
              canton: member.des_nombre_c,
              distrito: member.des_nombre_d,
              jrv: member.cod_mesa,
              puesto: Consts.puestosMesa.filter(status => {
                return status.codigo === member.cod_puesto;
              })[0].descripcion,
              cedula: member.num_cedula,
              nombre: `${member.des_nombre} ${member.des_apell1} ${member.des_apell2}`,
              id: member.num_linea,
            };
          });
          // console.log(itemsList);
          generateGafete('Miembro Mesa', itemsList, 1);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Gafetes Electorales: Miembros de Mesa
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {formik => (
                <>
                  {renderForm()}
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: 'right' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={generateGafetes(
                          formik.values,
                        )}
                        style={{ marginTop: 10 }}
                      >
                        GENERAR GAFETES
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>

      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default GafetesMiembrosMesa;
