/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Lodash from 'lodash';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Button,
  TextField,
  Divider,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  PlnInput,
  PlnError,
  PlnCheckbox,
} from '../../Components/Input';
import style from './styles';

import {
  findGestor,
  saveGestor,
} from '../../Core/Api/gestorApi';
import { searchByCedula } from '../../Core/Api/padronApi';
import { sendPLNMail } from '../../Core/Api/mailApi';
import REGEX from '../../Core/Utils/RegExpUtils';

import { useIGESAvailable } from '../../Core/Api/periodoApi';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const RegistroGestores = props => {
  const formGestorRegister = useRef();
  // Se valida si esta habilitada la opcion (Registro Gestores)
  const isRegistroGESAvailable = useIGESAvailable();
  const registroGESAvailable = Lodash.get(
    isRegistroGESAvailable,
    ['data', 'response'],
    null,
  );

  /* eslint new-cap: ["error", { "newIsCap": false }] */
  const doc = new jsPDF('p', 'px', 'letter');
  const { classes } = props;
  const [showForm, setShowForm] = useState(false);
  const [termConditions, setTermConditions] = useState(
    true,
  );
  const [gestorData, setGestorData] = useState({
    des_apell1: '',
    des_apell2: '',
    des_nombre: '',
    des_nombre_p: '',
    des_nombre_c: '',
    des_nombre_d: '',
    des_nombre_ad: '',
    tel_casa: '',
    tel_celu: '',
    tel_ofi: '',
    des_email: '',
    des_email2: '',
    des_direccion: '',
    des_direccion_trabajo: '',
  });

  const frmInitValues = {
    telCasa: '',
    telCelu: '',
    telOfi: '',
    dirDomicilio: '',
    dirTrabajo: '',
    email1: '',
    email2: '',
    reemail1: '',
    nickname: '',
    passUser: '',
    termConditions,
  };

  const [iCedula, setICedula] = useState('');
  const [iValues2, setIValues2] = useState(frmInitValues);
  const [msjErrorCedula, setMsjErrorCedula] = useState('');
  const [
    msjUserRegistered,
    setMsjUserRegistered,
  ] = useState('');

  const initialValues = {
    cedula: iCedula,
  };

  const initialValues2 = iValues2;

  const validationSchema = Yup.object({});

  const formikValidate = values => {
    const errors = {};
    const { cedula } = values;

    if (!cedula) {
      errors.cedula = 'Requerido';
    } else if (cedula.length <= 8) {
      errors.cedula =
        'Debe utilizar el siguiente formato: 102340567';
    } else if (!REGEX.cedula.test(cedula)) {
      errors.cedula = 'Solo se permiten números';
    }

    return errors;
  };

  const formikValidationAfterCed = values => {
    // validationSchema2
    const errors = {};
    const {
      email1,
      nickname,
      passUser,
      // eslint-disable-next-line no-shadow
      termConditions,
    } = values;

    if (!REGEX.email.test(email1)) {
      errors.email1 = 'Email inválido';
    }

    if (!nickname) {
      errors.nickname = 'Requerido';
    } else if (!REGEX.nickname.test(nickname)) {
      errors.nickname = (
        <ul>
          <li>Requiere un mínimo de 6 caracteres</li>
          <li>No se permiten espacios</li>
        </ul>
      );
    }

    if (!termConditions) {
      errors.termConditions =
        '* Debe de haber leído y aceptado los terminos y condiciones';
    }

    if (!passUser) {
      errors.passUser = 'Requerido';
    } else if (!REGEX.password.test(passUser)) {
      errors.passUser = (
        <ul>
          <li>Requiere un mínimo de 8 caracteres</li>
          <li>Debe tener al menos una letra mayúscula</li>
          <li>Debe tener al menos una letra minúscula</li>
          <li>
            Debe tener números y caracteres especiales
          </li>
        </ul>
      );
    }

    return errors;
  };

  const centeredText = (text, y) => {
    const textWidth =
      (doc.getStringUnitWidth(text) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset =
      (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
  };

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] = React.useState(
    false,
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  const closeFinalDialog = () => {
    setIValues2(frmInitValues);
    setShowForm(false);
    setICedula('');
  };

  const generateLetter = (
    gestorName,
    gestorCedula,
    gestorCorreo,
  ) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    };
    const today = new Date();
    const currentDate = today.toLocaleDateString(
      'es-ES',
      options,
    );

    doc.setFontSize(14);
    doc.setFont('times', 'bold');
    centeredText('PARTIDO LIBERACIÓN NACIONAL', 50);
    centeredText('TRIBUNAL DE ELECCIONES INTERNAS', 65);
    doc.text(275, 100, `San Jose, ${currentDate}`);

    doc.setFont('times', 'normal');
    doc.text(30, 120, 'Señoras y Señores');
    doc.text(30, 135, 'Tribunal de Elecciones Internas');
    doc.text(30, 150, 'Partido Liberación Nacional');

    let paragraph = `Yo ${gestorName}, mayor de edad, cédula de identidad ${gestorCedula}, por la presente me comprometo y obligo a guardar absoluta confidencialidad sobre todos los datos e información de carácter personal que conozca o a los cuales tenga acceso como consecuencia de la presentación de servicio como gestor (a) durante las Asambleas Distritales y de Movimientos y Sectores.`;
    let lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 175, lines);

    paragraph =
      'El acceso y tratamiento de los datos de carácter personal, como consecuencia de la relación establecida con el candidato o candidata, se realizará de conformidad con las finalidades previstas, subsistiendo el deber de secreto.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 255, lines);

    paragraph =
      'Asimismo, me comprometo a efectuar, de forma personal, los trámites relacionados con la gestión de la papeleta o candidatura, ser el responsable de la misma frente al Tribunal de Elecciones Internas para todos los efectos y alcances del proceso de tramitación e inscripción, lo cual incluye prevenciones por omisiones, formulación de consultas, presentación de apelaciones y gestiones de exclusión; además actuar conforme las facultades que otorga el artículo 1256 del Código Civil vigente.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 305, lines);

    paragraph =
      'Acepto dar fe de la veracidad de la información y datos contenidos en las solicitudes, así como respetar en su totalidad el marco normativo, ético y jurídico que rige el proceso, así como la responsabilidad de salvaguardar los intereses de mis representados(as) y las decisiones, resoluciones y acuerdos del Tribunal de Elecciones Internas.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 395, lines);

    const columns = ['A'];
    const data = [
      ['__________________________________________'],
      ['FIRMA'],
    ];

    doc.autoTable(columns, data, {
      theme: 'plain',
      styles: {
        overflow: 'linebreak',
        columnWidth: 'wrap',
        font: 'times',
        fontSize: 14,
      },
      columnStyles: {
        0: { columnWidth: 425 },
      },
      headerStyles: {
        fillColor: [204, 204, 204],
        fontSize: 16,
        textColor: 0,
        halign: 'center',
      },
      showHeader: 'never',
      margin: { top: 460, left: 27 },
    });

    doc.setFontSize(12);
    paragraph = `Correo electrónico para notificaciones: ${gestorCorreo}`;
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 505, lines);

    doc.addPage();

    doc.setFontSize(14);
    doc.setFont('times', 'bold');
    paragraph = 'TÉRMINOS Y CONDICIONES DE USO';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 50, lines);

    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    paragraph = 'Información relevante';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 70, lines);

    doc.setFontSize(10);
    doc.setFont('times', 'normal');
    paragraph =
      'Al solicitar la generación de su usuario y contraseña como gestor ante el Tribunal de Elecciones Internas (en adelante el Tribunal), para la inscripción de papeletas y candidaturas que se inscribirán para el proceso de asambleas distritales y asambleas de los movimientos de Mujeres, Juventud, Trabajadores y Cooperativo, usted está aceptando los términos y condiciones que a continuación se detallan';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 90, lines);

    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    paragraph = 'Uso personal.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 130, lines);

    doc.setFontSize(10);
    doc.setFont('times', 'normal');
    paragraph =
      'La persona gestora deberá señalar un correo electrónico para recibir notificaciones, el cual pasará a ser el único medio jurídicamente válido para tales efectos. De conformidad con las disposiciones que rigen el proceso, será responsabilidad del gestor mantener debidamente habilitado y en funcionamiento este medio.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 150, lines);

    paragraph =
      'Asimismo, tendrá que elegir un usuario y una contraseña, para acceder al sistema de inscripción en línea y realizar el registro de las postulaciones bajo su gestoría.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 180, lines);

    paragraph =
      'Es obligatorio el registro de datos reales y fidedignos que permitan asegurar la identidad del gestor. El usuario y contraseña que se generen son de uso estrictamente personal. El Tribunal de Elecciones Internas no se hará responsable por el empleo inadecuado que se produzca.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 220, lines);

    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    paragraph = 'Tratamiento de la información.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 240, lines);

    doc.setFontSize(10);
    doc.setFont('times', 'normal');
    paragraph =
      'El acceso y tratamiento de los datos de carácter personal, se realizará con la finalidad de contar con información de contacto, en caso de requerirse. En todo momento subsistirá el deber de secreto.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 280, lines);

    paragraph =
      'Por ello, se autoriza al Partido Liberación Nacional para que se utilice la información suministrada, únicamente, para mantener contacto con el gestor. Este será el único medio oficial, para cualquier tipo de notificación en relación con la inscripción de papeletas, nóminas o candidaturas.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 310, lines);

    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    paragraph = 'Información veraz.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 330, lines);

    doc.setFontSize(10);
    doc.setFont('times', 'normal');
    paragraph =
      'El gestor da fe de la veracidad de la información digitada, así como de los datos contenidos en las solicitudes de inscripción y demás documentos que deben acompañar la inscripción de una candidatura o papeleta.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 360, lines);

    paragraph =
      'No obstante lo anterior, el Tribunal podrá solicitarle al gestor la documentación física que respalda la inscripción, para lo cual el mismo tendrá un plazo máximo 48 horas para presentar los mismos ante la Casa Liberacionista José Figueres Ferrer. En caso de no realizarlo, el Tribunal procederá con la anulación de las candidaturas o papeletas.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 390, lines);

    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    paragraph = 'Pago.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 410, lines);

    doc.setFontSize(10);
    doc.setFont('times', 'normal');
    paragraph =
      'El sistema de inscripción en línea detalla el monto a cancelar por el correspondiente registro de las papeletas o candidaturas que el gestor realice. El gestor está en la obligación de verificar dicho monto y proceder con el registro del depósito correspondiente.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 430, lines);

    paragraph =
      'En caso de no realizar el pago completo, se procederá con el rechazo de las inscripciones realizadas.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 460, lines);

    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    paragraph = 'Inscripción en línea.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 480, lines);

    doc.setFontSize(10);
    doc.setFont('times', 'normal');
    paragraph =
      'El gestor entiende que el proceso de inscripción de papeletas y candidaturas será únicamente en línea, en las fechas y horas señaladas en la convocatoria.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 510, lines);

    paragraph =
      'Los gestores deberán realizar la inscripción en línea, bajo el sistema que el Tribunal pondrá a disposición y con sus respectivos requisitos. La inscripción registrada en línea será la definitiva y válida para participar en el proceso.';
    lines = doc.splitTextToSize(paragraph, 390);
    doc.text(30, 540, lines);

    doc.save(
      `COMPROBANTE-REGISTRO-GESTOR-${process.env.REACT_APP_PDF_YEAR_REPORTS}.pdf`,
    );
  };

  const sendMailNotification = () => {
    /**
     * Se cambia el servidor de Correo (SendMail -> EmailJs) se realiza la configuracion
     * Fecha del cambio: 13/12/2024
     */

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env
          .REACT_APP_EMAILJS_TEMPLATE_REGISTRO_GESTORES,
        formGestorRegister.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      )
      .then(
        result => {
          // eslint-disable-next-line no-console
          console.log(result);
        },
        error => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
      );
  };

  const loadInformation = (values, { setSubmitting }) => {
    setOpenDialog(false);
    setFinalDialog(false);
    const { cedula } = values;
    setShowForm(false);
    setMsjErrorCedula('');
    setMsjUserRegistered('');
    findGestor(cedula).then(result => {
      const response = Lodash.get(result, [
        'data',
        'response',
      ]);
      if (response === false) {
        searchByCedula(cedula).then(r => {
          const data = Lodash.get(
            r,
            ['data', 'response'],
            [],
          );
          if (data !== false) {
            setShowForm(true);
            const gestorInfo = Lodash.get(data, [0]);
            setGestorData(gestorInfo);
            setICedula(gestorInfo.num_cedula);
            setIValues2({
              telCasa: gestorInfo.tel_casa,
              telCelu: gestorInfo.tel_celu,
              telOfi: gestorInfo.tel_ofi
                ? gestorInfo.tel_ofi
                : '',
              dirDomicilio: gestorInfo.des_direccion,
              dirTrabajo: gestorInfo.des_direccion_trabajo
                ? gestorInfo.des_direccion_trabajo
                : '',
              email1: gestorInfo.des_email,
              email2: gestorInfo.des_email2
                ? gestorInfo.des_email2
                : '',
              reemail1: '',
              nickname: gestorInfo.num_cedula,
              passUser: '',
              termConditions: false,
            });
          } else {
            setMsjErrorCedula(
              'El número de cédula ingresado no coincide con ninguna referencia',
            );
            setShowForm(false);
          }
          setSubmitting(false);
        });
      } else {
        setMsjUserRegistered(
          // eslint-disable-next-line max-len
          'Este usuario Ya fue registrado como Gestor. Favor comunicarse con Ezequiel Hernández al 2519-5521 o al correo ehernandez@plndigital.com',
        );
        setShowForm(false);
        setSubmitting(false);
      }
    });
  };

  const addGestor = values => {
    setMsjDialog('');
    setOpenDialog(false);

    const {
      dirDomicilio,
      dirTrabajo,
      email1,
      email2,
      nickname,
      passUser,
      // reemail1,
      telCasa,
      telCelu,
      telOfi,
    } = values;

    const gestor = {
      num_cedula: gestorData.num_cedula,
      nickname,
    };

    const userData = {
      des_nombre_c: gestorData.des_nombre_c,
      des_nombre_d: gestorData.des_nombre_d,
      des_nombre_ad: gestorData.des_nombre_ad,
      des_nombre_p: gestorData.des_nombre_p,
      cod_prov: gestorData.cod_prov,
      cod_cant: gestorData.cod_cant,
      cod_dist_sede: gestorData.cod_dist_sede,
      cod_dist_adm: gestorData.cod_dist_adm,
      num_jvirt: gestorData.num_jvirt,
      des_nombre: gestorData.des_nombre,
      des_apell1: gestorData.des_apell1,
      des_apell2: gestorData.des_apell2,
      num_cedula: gestorData.num_cedula,
      num_edad: gestorData.num_edad,
      fec_caduc: gestorData.fec_caduc,
      sexo: gestorData.sexo,
      des_email: email1,
      des_email2: email2,
      tel_casa: telCasa,
      tel_celu: telCelu,
      tel_ofi: telOfi,
      tel_res: gestorData.tel_res,
      tel_cel_n: gestorData.tel_cel_n,
      num_fax: gestorData.num_fax,
      num_fax_ofi: gestorData.num_fax_ofi,
      des_direccion: dirDomicilio,
      des_direccion_trabajo: dirTrabajo,
      des_nombre_centro: gestorData.des_nombre_centro,
      nickname,
      passUser,
    };

    const telefonosData = {
      tel_casa: telCasa,
      tel_celu: telCelu,
      tel_ofi: telOfi,
    };
    const direccionesData = {
      des_direccion: dirDomicilio,
      des_direccion_trabajo: dirTrabajo,
    };
    const emailsData = {
      des_email: email1,
      des_email2: email2,
    };

    const gestorInfo = {
      gestorData: gestor,
      userData,
      telefonosData,
      direccionesData,
      emailsData,
    };

    if (registroGESAvailable) {
      saveGestor(gestorInfo).then(result => {
        const response = Lodash.get(result, [
          'data',
          'response',
        ]);
        if (response > 0) {
          /**
           * Se Elimina la generacion del documento para el periodo 2025
           * Fecha de Cambio: 12/12/2024
           *
           */
          const gestorDataMail = {
            full_name: `${userData.des_nombre} ${userData.des_apell1} ${userData.des_apell2}`,
            des_email: userData.des_email,
            des_email2: userData.des_email2,
          };
          // setIValues2(frmInitValues);
          // setShowForm(false);
          setFinalDialog(true);
          setMsjDialog('Gestor Registrado Exitosamente!');
          // setICedula('');
          sendMailNotification();
        } else {
          if (response === -1) {
            setShowForm(false);
            setOpenDialog(true);
            setMsjDialog(
              'El tiempo para registrase como gestor ha caducado. No se ha podido crear la cuenta.',
            );
          } else {
            setOpenDialog(true);
            setMsjDialog(
              'Ha Ocurrido un Error! Su cuenta NO ha podido ser creada, favor intentarlo nuevamente.',
            );
          }
        }
      });
    } else {
      setOpenDialog(true);
      setMsjDialog(
        'El tiempo para registrase como gestor ha caducado. No se ha podido crear la cuenta.',
      );
    }
  };

  const changeTermConditions = val => {
    setTermConditions(val);
  };

  // Re-Confirmar email
  /*
    <h4>
    * Re-confirme el Correo Electrónico
    principal
    </h4>
    <Field
    name="reemail1"
    component={PlnInput}
    type="text"
    style={{ marginTop: 20 }}
    />
    <ErrorMessage
    component={PlnError}
    name="reemail1"
    />
  */

  return (
    <React.Fragment>
      {process.env.REACT_APP_OPTION_REGISTRO_GESTORES ===
        'enabled' && registroGESAvailable ? (
        <Formik
          validate={formikValidate}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={loadInformation}
        >
          {({ errors, touched, isSubmitting }) => {
            return (
              <Form>
                <Container
                  maxWidth="lg"
                  className={classes.container}
                >
                  <h3>REGISTRO DE GESTORES</h3>
                  <Grid
                    container
                    spacing={3}
                    style={{ marginTop: 20 }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#008945',
                        color: '#fff',
                      }}
                    >
                      <h4>
                        Ingrese el número de cédula del
                        Gestor y complete la información
                        requerida.
                      </h4>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <h4>Número de Cédula</h4>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        mask="999999999"
                        name="cedula"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      {errors.cedula && touched.cedula ? (
                        <div className={classes.formError}>
                          <Alert severity="error">
                            {errors.cedula}
                          </Alert>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ textAlign: 'center' }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        CARGAR DATOS
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider />
                  {isSubmitting ? (
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: 'center',
                        padding: 10,
                      }}
                    >
                      <CircularProgress
                        className={classes.spinner}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: 'center',
                        padding: 10,
                        color: '#cc0000',
                      }}
                    >
                      {msjErrorCedula}
                      {msjUserRegistered}
                    </Grid>
                  )}
                </Container>
              </Form>
            );
          }}
        </Formik>
      ) : registroGESAvailable === null ? (
        <Container
          maxWidth="lg"
          className={classes.container}
          style={{ textAlign: 'center' }}
        >
          <h3>
            <CircularProgress />
          </h3>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          className={classes.container}
          style={{
            textAlign: 'center',
          }}
        >
          <h3>REGISTRO DE GESTORES</h3>
          <Grid
            container
            spacing={3}
            style={{ marginTop: 20 }}
          >
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                backgroundColor: '#FFE599',
                color: '#FF9326',
              }}
            >
              <h4>
                Esta sección se encuentra temporalmente
                deshabilitada. <br />
                Favor comunicarse con Ezequiel Hernández al
                2519-5521 o al correo
                ehernandez@plndigital.com
              </h4>
            </Grid>
          </Grid>
        </Container>
      )}
      {showForm ? (
        <React.Fragment>
          <Container
            maxWidth="lg"
            className={classes.container}
            style={{ marginTop: '30px' }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={4}>
                <h4>Primer Apellido</h4>
                <TextField
                  name="apellido1"
                  type="text"
                  value={gestorData.des_apell1}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ borderRadius: 10 }}
                />
              </Grid>
              <Grid item xs={4}>
                <h4>Segundo Apellido</h4>
                <TextField
                  name="apellido2"
                  type="text"
                  value={gestorData.des_apell2}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ borderRadius: 10 }}
                />
              </Grid>
              <Grid item xs={4}>
                <h4>Nombre</h4>
                <TextField
                  name="nombre_user"
                  type="text"
                  value={gestorData.des_nombre}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ borderRadius: 10 }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={3}>
                <h4>Provincia</h4>
                <TextField
                  name="apellido1"
                  type="text"
                  value={gestorData.des_nombre_p}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ borderRadius: 10 }}
                />
              </Grid>
              <Grid item xs={3}>
                <h4>Cantón</h4>
                <TextField
                  name="apellido2"
                  type="text"
                  value={gestorData.des_nombre_c}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ borderRadius: 10 }}
                />
              </Grid>
              <Grid item xs={3}>
                <h4>Distrito</h4>
                <TextField
                  name="apellido2"
                  type="text"
                  value={gestorData.des_nombre_d}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ borderRadius: 10 }}
                />
              </Grid>
              <Grid item xs={3}>
                <h4>Distrito Admin.</h4>
                <TextField
                  name="apellido2"
                  type="text"
                  value={gestorData.des_nombre_ad}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ borderRadius: 10 }}
                />
              </Grid>
            </Grid>
          </Container>
          <Formik
            enableReinitialize
            validate={formikValidationAfterCed}
            validationSchema={validationSchema}
            initialValues={initialValues2}
            onSubmit={addGestor}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form ref={formGestorRegister}>
                <Container
                  maxWidth="lg"
                  className={classes.container}
                  style={{ paddingBottom: '150px' }}
                >
                  <Grid
                    container
                    spacing={3}
                    style={{ marginTop: 20 }}
                  >
                    <Grid item xs={12}>
                      <h4 className={classes.subtitle}>
                        COMPLETE LA SIGUIENTE INFORMACIÓN
                      </h4>
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Teléfono Casa</h4>
                      <Field
                        name="telCasa"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      <ErrorMessage
                        component={PlnError}
                        name="telCasa"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>
                        Correo Electrónico (secundario)
                      </h4>
                      <Field
                        name="email2"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      {errors.email2 && touched.email2 ? (
                        <div className={classes.formError}>
                          <Alert severity="error">
                            {errors.email2}
                          </Alert>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Teléfono Celular</h4>
                      <Field
                        name="telCelu"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      {errors.telCelu && touched.telCelu ? (
                        <div className={classes.formError}>
                          <Alert severity="error">
                            {errors.telCelu}
                          </Alert>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Teléfono Trabajo</h4>
                      <Field
                        name="telOfi"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      <ErrorMessage
                        component={PlnError}
                        name="telOfi"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Dirección Domicilio</h4>
                      <Field
                        name="dirDomicilio"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      <ErrorMessage
                        component={PlnError}
                        name="dirDomicilio"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Dirección Trabajo</h4>
                      <Field
                        name="dirTrabajo"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      <ErrorMessage
                        component={PlnError}
                        name="dirTrabajo"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>
                        * Correo Electrónico (principal)
                      </h4>
                      <Field
                        name="email1"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 20 }}
                      />
                      <input
                        type="hidden"
                        name="from_name"
                        value="PLN Digital"
                      />
                      <input
                        type="hidden"
                        name="to_name"
                        value={`${gestorData.des_nombre} ${gestorData.des_apell1} ${gestorData.des_apell2}`}
                      />
                      {errors.email1 && touched.email1 ? (
                        <div className={classes.formError}>
                          <Alert severity="error">
                            {errors.email1}
                          </Alert>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={classes.subtitle}>
                        TÉRMINOS Y CONDICIONES DE USO
                      </h4>
                      <div
                        style={{
                          fontSize: 12,
                          overflowY: 'scroll',
                          height: '500px',
                        }}
                      >
                        <h5>Información relevante.</h5>
                        <p>
                          Al solicitar la generación de su
                          usuario y contraseña como gestor
                          ante el Tribunal de Elecciones
                          Internas (en adelante el
                          Tribunal), para la inscripción de
                          papeletas y candidaturas que se
                          inscribirán para el proceso de
                          asambleas distritales y asambleas
                          de los movimientos de Mujeres,
                          Juventud, Trabajadores y
                          Cooperativo, usted está aceptando
                          los términos y condiciones que a
                          continuación se detallan.
                        </p>

                        <h5>Uso personal.</h5>
                        <p>
                          La persona gestora deberá señalar
                          un correo electrónico para recibir
                          notificaciones, el cual pasará a
                          ser el único medio jurídicamente
                          válido para tales efectos. De
                          conformidad con las disposiciones
                          que rigen el proceso, será
                          responsabilidad del gestor
                          mantener debidamente habilitado y
                          en funcionamiento este medio.
                        </p>
                        <p>
                          Asimismo, tendrá que elegir un
                          usuario y una contraseña, para
                          acceder al sistema de inscripción
                          en línea y realizar el registro de
                          las postulaciones bajo su
                          gestoría.
                        </p>
                        <p>
                          Es obligatorio el registro de
                          datos reales y fidedignos que
                          permitan asegurar la identidad del
                          gestor. El usuario y contraseña
                          que se generen son de uso
                          estrictamente personal. El
                          Tribunal de Elecciones Internas no
                          se hará responsable por el empleo
                          inadecuado que se produzca.
                        </p>

                        <h5>
                          Tratamiento de la información.
                        </h5>
                        <p>
                          El acceso y tratamiento de los
                          datos de carácter personal, se
                          realizará con la finalidad de
                          contar con información de
                          contacto, en caso de requerirse.
                          En todo momento subsistirá el
                          deber de secreto.
                        </p>
                        <p>
                          Por ello, se autoriza al Partido
                          Liberación Nacional para que se
                          utilice la información
                          suministrada, únicamente, para
                          mantener contacto con el gestor.
                          Este será el único medio oficial,
                          para cualquier tipo de
                          notificación en relación con la
                          inscripción de papeletas, nóminas
                          o candidaturas.
                        </p>

                        <h5>Información veraz.</h5>
                        <p>
                          El gestor da fe de la veracidad de
                          la información digitada, así como
                          de los datos contenidos en las
                          solicitudes de inscripción y demás
                          documentos que deben acompañar la
                          inscripción de una candidatura o
                          papeleta.
                        </p>
                        <p>
                          No obstante lo anterior, el
                          Tribunal podrá solicitarle al
                          gestor la documentación física que
                          respalda la inscripción, para lo
                          cual el mismo tendrá un plazo
                          máximo 48 horas para presentar los
                          mismos ante la Casa Liberacionista
                          José Figueres Ferrer. En caso de
                          no realizarlo, el Tribunal
                          procederá con la anulación de las
                          candidaturas o papeletas.
                        </p>

                        <h5>Pago.</h5>
                        <p>
                          El sistema de inscripción en línea
                          detalla el monto a cancelar por el
                          correspondiente registro de las
                          papeletas o candidaturas que el
                          gestor realice. El gestor está en
                          la obligación de verificar dicho
                          monto y proceder con el registro
                          del depósito correspondiente.
                        </p>
                        <p>
                          En caso de no realizar el pago
                          completo, se procederá con el
                          rechazo de las inscripciones
                          realizadas.
                        </p>

                        <h5>Inscripción en línea.</h5>
                        <p>
                          El gestor entiende que el proceso
                          de inscripción de papeletas y
                          candidaturas será únicamente en
                          línea, en las fechas y horas
                          señaladas en la convocatoria.
                        </p>
                        <p>
                          Los gestores deberán realizar la
                          inscripción en línea, bajo el
                          sistema que el Tribunal pondrá a
                          disposición y con sus respectivos
                          requisitos. La inscripción
                          registrada en línea será la
                          definitiva y válida para
                          participar en el proceso.
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <h4
                        style={{
                          textAlign: 'center',
                          color: '#fff',
                          padding: 15,
                          backgroundColor: '#008945',
                        }}
                      >
                        Digite sus Credenciales de Acceso
                      </h4>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12}>
                          <h4>* Usuario</h4>
                          <Field
                            name="nickname"
                            component={PlnInput}
                            type="text"
                          />
                          {errors.nickname &&
                          touched.nickname ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {errors.nickname}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <h4>* Contraseña</h4>
                          <Field
                            name="passUser"
                            component={PlnInput}
                            type="password"
                          />
                          {errors.passUser &&
                          touched.passUser ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {errors.passUser}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="termConditions"
                            id="termConditions"
                            component={PlnCheckbox}
                            label="He Leído y Acepto los términos y condiciones"
                            checked={false}
                            onChange={isChecked =>
                              changeTermConditions(
                                isChecked,
                              )
                            }
                          />
                          {errors.termConditions &&
                          touched.termConditions ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {errors.termConditions}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ marginTop: '80px' }}
                            disabled={isSubmitting}
                          >
                            REGISTRAR
                          </Button>
                        </Grid>
                      </Grid>
                      <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogTitle
                          id="alert-confirm-messages"
                          color="warning"
                        >
                          Mensaje
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            {msjDialog}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleClose}
                            color="primary"
                          >
                            Entendido
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={finalDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={closeFinalDialog}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogTitle
                          id="alert-finalDialog"
                          color="warning"
                        >
                          Mensaje
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            {msjDialog}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={closeFinalDialog}
                            color="primary"
                          >
                            Cerrar
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default withStyles(style)(RegistroGestores);
