const distritalesInitValues = {
  provincia: 9,
  canton: 999,
  distrito: 999,
  papeleta: 999,
  delegado1: '',
  delegado2: '',
  delegado3: '',
  delegado4: '',
  delegado5: '',
  delegado6: '',
  delegado7: '',
  delegado8: '',
  delegado9: '',
  presidente: '',
  vicePresidente: '',
  secretario: '',
  subSecretario: '',
  tesoreria: '',
  subTesoreria: '',
  propietario: '',
};
const cantonalesInitValues = {
  provincia: 9,
  canton: 999,
  movimiento: 9,
  papeleta: 999,
  cantonal1: '',
  cantonal2: '',
  cantonal3: '',
  cantonal4: '',
  cantonal5: '',
  cantonal6: '',
  cantonal7: '',
};
const provincialesInitValues = {
  provincia: 9,
  movimiento: 9,
  papeleta: 999,
  provincial1: '',
  provincial2: '',
  provincial3: '',
  provincial4: '',
};
const nacionalesInitValues = {
  movimiento: 9,
  puesto: 9,
  nacional1: '',
  nacional2: '',
  nacional3: '',
};
const presidenteSectorInitValues = {
  presidente: '',
};
const sectorialesInitValues = {
  sector: 9,
  sectorial1: '',
  sectorial2: '',
  sectorial3: '',
};
const initListDelegados = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'delegado1',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 2,
    lugar: 2,
    numPuesto: 2,
    fieldName: 'delegado2',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 3,
    lugar: 3,
    numPuesto: 3,
    fieldName: 'delegado3',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 4,
    lugar: 4,
    numPuesto: 4,
    fieldName: 'delegado4',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 5,
    lugar: 5,
    numPuesto: 5,
    fieldName: 'delegado5',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 6,
    lugar: 6,
    numPuesto: 6,
    fieldName: 'delegado6',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 7,
    lugar: 7,
    numPuesto: 7,
    fieldName: 'delegado7',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 8,
    lugar: 8,
    numPuesto: 8,
    fieldName: 'delegado8',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 9,
    lugar: 9,
    numPuesto: 9,
    fieldName: 'delegado9',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
];

const initListComite = [
  {
    id: 1,
    lugar: 'Presidente',
    numPuesto: 10,
    fieldName: 'presidente',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 2,
    lugar: 'Vicepresidente',
    numPuesto: 11,
    fieldName: 'vicePresidente',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 3,
    lugar: 'Secretario',
    numPuesto: 12,
    fieldName: 'secretario',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 4,
    lugar: 'Subsecretario',
    numPuesto: 13,
    fieldName: 'subSecretario',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 5,
    numPuesto: 14,
    lugar: 'Tesorería',
    fieldName: 'tesoreria',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 6,
    lugar: 'Subtesorería',
    numPuesto: 15,
    fieldName: 'subTesoreria',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
];

const initListCandidatosFiscalia = [
  {
    id: 1,
    lugar: 'Propietario',
    numPuesto: 16,
    fieldName: 'propietario',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
];

const initListComiteCantonal = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'cantonal1',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 2,
    lugar: 2,
    numPuesto: 2,
    fieldName: 'cantonal2',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 3,
    lugar: 3,
    numPuesto: 3,
    fieldName: 'cantonal3',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 4,
    lugar: 4,
    numPuesto: 4,
    fieldName: 'cantonal4',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 5,
    lugar: 5,
    numPuesto: 5,
    fieldName: 'cantonal5',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 6,
    lugar: 6,
    numPuesto: 6,
    fieldName: 'cantonal6',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 7,
    lugar: 7,
    numPuesto: 7,
    fieldName: 'cantonal7',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
];

const initlistComiteProvincial = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'provincial1',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 2,
    lugar: 2,
    numPuesto: 2,
    fieldName: 'provincial2',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 3,
    lugar: 3,
    numPuesto: 3,
    fieldName: 'provincial3',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 4,
    lugar: 4,
    numPuesto: 4,
    fieldName: 'provincial4',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
];

const initListComiteNacional = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'nacional1',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 2,
    lugar: 2,
    numPuesto: 2,
    fieldName: 'nacional2',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 3,
    lugar: 3,
    numPuesto: 3,
    fieldName: 'nacional3',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
];

const initlistPresiSector = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'presidente',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
];

const initListComiteSectorial = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'sectorial1',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 2,
    lugar: 2,
    numPuesto: 2,
    fieldName: 'sectorial2',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
  {
    id: 3,
    lugar: 3,
    numPuesto: 3,
    fieldName: 'sectorial3',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: true,
    filled: false,
  },
];

const initListAddDeposito = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'depositante',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
];

const initlistPapeletaSector = [
  {
    id: 1,
    lugar: 1,
    numPuesto: 1,
    fieldName: 'candidato1',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 2,
    lugar: 2,
    numPuesto: 2,
    fieldName: 'candidato2',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
  {
    id: 3,
    lugar: 3,
    numPuesto: 3,
    fieldName: 'candidato3',
    cedula: '',
    nombre: '',
    sexo: '',
    edad: '',
    enabled: false,
    filled: false,
  },
];

const delegadosMOrder = [
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
];
const delegadosFOrder = [
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
];

const comiteMOrder = [
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
];

const comiteFOrder = [
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
];

const comiteCantonalMOrder = [
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
];

const comiteCantonalFOrder = [
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
];

const comiteProvincialMOrder = [
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
];

const comiteProvincialFOrder = [
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
];

const comiteNacionalMOrder = [
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
];

const comiteNacionalFOrder = [
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
];

const presiSectorMOrder = [
  {
    sexo: 'M',
  },
];
const presiSectorFOrder = [
  {
    sexo: 'F',
  },
];
const comiteSectorMOrder = [
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
];
const comiteSectorFOrder = [
  {
    sexo: 'F',
  },
  {
    sexo: 'M',
  },
  {
    sexo: 'F',
  },
];

const puestosDelegadosDistritales = [
  {
    codigo: 1,
    descripcion: '# 1',
  },
  {
    codigo: 2,
    descripcion: '# 2',
  },
  {
    codigo: 3,
    descripcion: '# 3',
  },
  {
    codigo: 4,
    descripcion: '# 4',
  },
  {
    codigo: 5,
    descripcion: '# 5',
  },
  {
    codigo: 6,
    descripcion: '# 6',
  },
  {
    codigo: 7,
    descripcion: '# 7',
  },
  {
    codigo: 8,
    descripcion: '# 8',
  },
  {
    codigo: 9,
    descripcion: '# 9',
  },
  {
    codigo: 10,
    descripcion: 'PRESIDENCIA',
  },
  {
    codigo: 11,
    descripcion: 'VICEPRESIDENCIA',
  },
  {
    codigo: 12,
    descripcion: 'SECRETARIO(A)',
  },
  {
    codigo: 13,
    descripcion: 'SUBSECRETARIO(A)',
  },
  {
    codigo: 14,
    descripcion: 'TESORERO(A)',
  },
  {
    codigo: 15,
    descripcion: 'SUBTESORERO(A)',
  },
  {
    codigo: 16,
    descripcion: 'FISCAL PROPIETARIO',
  },
  {
    codigo: 17,
    descripcion: 'FISCAL SUPLENTE',
  },
];

const puestosComiteCantonal = [
  {
    codigo: 1,
    descripcion: '# 1 (PRESID)',
  },
  {
    codigo: 2,
    descripcion: '# 2',
  },
  {
    codigo: 3,
    descripcion: '# 3',
  },
  {
    codigo: 4,
    descripcion: '# 4',
  },
  {
    codigo: 5,
    descripcion: '# 5',
  },
  {
    codigo: 6,
    descripcion: '# 6',
  },
  {
    codigo: 7,
    descripcion: '# 7',
  },
];

const estadosDistritales = [
  {
    codigo: 2,
    estado: 'Registrada',
  },
  {
    codigo: 3,
    estado: 'Inscrita',
  },
  {
    codigo: 4,
    estado: 'Prevención',
  },
  {
    codigo: 5,
    estado: 'Anulada',
  },
];

const movimientosCantonales = [
  {
    codigo: 1,
    movimiento: 'Mujeres',
  },
  {
    codigo: 2,
    movimiento: 'Juventud',
  },
  {
    codigo: 3,
    movimiento: 'Cooperativo',
  },
  {
    codigo: 4,
    movimiento: 'Trabajadores',
  },
];

const movimientosSectoriales = [
  {
    codigo: 2,
    sector: 'Profesional',
  },
  {
    codigo: 3,
    sector: 'Educativo',
  },
  {
    codigo: 4,
    sector: 'Empesarial',
  },
];

const puestosMesa = [
  {
    codigo: 3,
    descripcion: 'Propietario',
  },
  {
    codigo: 4,
    descripcion: 'Suplente',
  },
];

const puestosFiscalesMesa = [
  {
    codigo: 5,
    descripcion: 'Propietario',
  },
  {
    codigo: 6,
    descripcion: 'Suplente',
  },
];

const puestosFiscalGeneral = [
  {
    codigo: 7,
    descripcion: 'Fiscal General',
  },
];

const puestosNacionales = [
  {
    codigo: 1,
    descripcion: 'Presidente',
  },
  {
    codigo: 2,
    descripcion: 'Vicepresidente',
  },
  {
    codigo: 3,
    descripcion: 'Secretario',
  },
];

const puestosRegistrosElectorales = [
  {
    codigo: 1,
    descripcion: 'Presid.',
  },
  {
    codigo: 2,
    descripcion: 'Secre.',
  },
];

const consts = {
  distritalesInitValues,
  cantonalesInitValues,
  provincialesInitValues,
  nacionalesInitValues,
  presidenteSectorInitValues,
  sectorialesInitValues,
  initListDelegados,
  initListComite,
  initListCandidatosFiscalia,
  initListComiteCantonal,
  initlistComiteProvincial,
  initListComiteNacional,
  initListComiteSectorial,
  initListAddDeposito,
  initlistPresiSector,
  initlistPapeletaSector,
  delegadosMOrder,
  delegadosFOrder,
  comiteMOrder,
  comiteFOrder,
  comiteCantonalMOrder,
  comiteCantonalFOrder,
  comiteProvincialMOrder,
  comiteProvincialFOrder,
  comiteNacionalMOrder,
  comiteNacionalFOrder,
  presiSectorMOrder,
  presiSectorFOrder,
  comiteSectorFOrder,
  comiteSectorMOrder,
  estadosDistritales,
  movimientosCantonales,
  movimientosSectoriales,
  puestosMesa,
  puestosFiscalesMesa,
  puestosFiscalGeneral,
  puestosNacionales,
  puestosRegistrosElectorales,
  puestosDelegadosDistritales,
  puestosComiteCantonal,
};

export default consts;
