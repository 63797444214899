/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import Slide from '@material-ui/core/Slide';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { PlnDropdown } from '../../Components/Input';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Copyright from '../Copyright/Copyright';

import {
  useProvincias,
  getCantonsByProvince,
  getDistritoByTwo,
} from '../../Core/Api/locationApi';

import miembrosMesaApi from '../../Core/Api/miembrosMesaApi';
import registrosElectoralesApi from '../../Core/Api/registrosElectoralesApi';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

function createData(_data) {
  return {
    province: _data.des_nombre_p,
    canton: _data.des_nombre_c,
    distrito: _data.des_nombre_d,
    jrv: _data.cod_mesa,
    puesto: _data.des_puesto,
    cedula: _data.num_cedula,
    primerApellido: _data.des_apell1,
    segundoApellido: _data.des_apell2,
    nombre: _data.des_nombre,
  };
}

const REFiscalesGenerales = props => {
  const { classes } = props;
  const { data } = useProvincias();

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState(9);
  const [sCanton, setSCanton] = useState(999);

  const [isDataEmpty, setDataEmpty] = useState(false);
  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', '', '', '', '', ''],
  ]); // used for filtering

  const heads = [
    {
      name: 'Provincia',
    },
    {
      name: 'Cantón',
    },
    {
      name: 'Distrito Electoral',
    },
    {
      name: 'JRV',
    },
    {
      name: 'Puesto',
    },
    {
      name: 'Cédula',
    },
    {
      name: '1er. Apellido',
    },
    {
      name: '2do. Apellido',
    },
    {
      name: 'Nombre',
    },
  ];

  // Lista de: Provincias, Cantones, Distritos y Movimientos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      canton: 999,
      distrito: 999,
      jrv: 999,
    }),
  );

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN -', value: 999 },
  ]);
  const [distritos, setDistritos] = useState([
    { itemLabel: '- SELECCIONE EL DISTRITO -', value: 999 },
  ]);

  const [jrv, setJRV] = useState([
    { itemLabel: '- SELECCIONE LA JRV -', value: 999 },
  ]);

  const validationSchema = Yup.object({});

  const setProvincia = p => {
    setCantones([
      {
        itemLabel: '- SELECCIONE EL CANTÓN -',
        value: 999,
      },
    ]);
    setDistritos([
      {
        itemLabel: '- SELECCIONE EL DISTRITO -',
        value: 999,
      },
    ]);
    setJRV([
      {
        itemLabel: '- SELECCIONE LA JRV -',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.canton = 999;
    initialValues.distrito = 999;
    initialValues.jrv = 999;
    if (p !== 9) {
      getCantonsByProvince(p).then(result => {
        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN -',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );
          setCantones(clist);
        }
      });
    }
  };

  const setCanton = c => {
    initialValues.distrito = 999;
    initialValues.provincia = sProvincia.value;
    initialValues.canton = c;
    initialValues.jrv = 999;

    if (c !== 999) {
      getDistritoByTwo(sProvincia.value, c).then(result => {
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);
          const dlist = distritosList.map(
            ({ cod_distrito, des_nombre_d }) => {
              return {
                itemLabel: des_nombre_d,
                value: cod_distrito,
              };
            },
          );
          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });
    } else {
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
    }
  };

  const getJRV = async _codDistr => {
    const currentDistr = parseInt(_codDistr, 10);

    if (currentDistr > 0 && currentDistr !== 999) {
      await miembrosMesaApi
        .getJuntaReceptora(
          sProvincia.value,
          sCanton.value,
          currentDistr,
        )
        .then(_res => {
          const listJRV = _res.data.response;
          if (listJRV instanceof Array) {
            const jrvlist = listJRV.map(
              // eslint-disable-next-line no-unused-vars
              ({ num_jvirt, index }) => {
                return {
                  itemLabel: `Nº ${num_jvirt}`,
                  value: num_jvirt,
                };
              },
            );
            jrvlist.unshift({
              itemLabel: '- SELECCIONE LA JRV -',
              value: 999,
            });

            setJRV(jrvlist);
          }
        });
    }
  };

  const getJRVSelected = _jrv => {
    const currentJRV = parseInt(_jrv, 10);

    if (currentJRV > 0 && currentJRV !== 999) {
      // console.log(currentJRV);
    }
  };

  const doSearch = values => async () => {
    setShowResults(true);
    setIsLoading(true);
    const { canton, distrito, provincia, jrv } = values;
    const codProvincia = provincia === 9 ? 0 : provincia;
    const codCanton = canton === 999 ? 0 : canton;
    const codDistrito = distrito === 999 ? 0 : distrito;
    const codJRV = jrv === 999 ? 0 : jrv;

    const filteredData = {
      codProvincia,
      codCanton,
      codDistrito,
      codJRV,
    };

    await registrosElectoralesApi
      .getFilteredFiscalesGenerales(filteredData)
      .then(response => {
        setIsLoading(false);
        if (response.status === 200) {
          const info = response.data[0];
          if (info.error.length > 0) {
            // console.log(info.error);
            setFilteredItems([]);
            setDataEmpty(true);
          } else {
            const totalItems =
              info.response['fGenerales'].length;

            let rowItems = [];
            if (totalItems > 0) {
              rowItems = info.response['fGenerales'].map(
                row => {
                  return createData(row);
                },
              );
            }

            setFilteredItems(rowItems);
            setDataEmpty(totalItems === 0);
          }
        } else {
          console.log(
            `Server status resolved: ${response.status}`,
          );
          setFilteredItems([]);
          setDataEmpty(true);
        }
      })
      .catch(_error => {
        setFilteredItems([]);
        setDataEmpty(true);
      });
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <h4>Provincia</h4>
            <Field
              name="provincia"
              data={provincias}
              component={PlnDropdown}
              onChange={provincia =>
                setProvincia(provincia)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <h4>Cantón</h4>
            <Field
              name="canton"
              data={cantones}
              component={PlnDropdown}
              onChange={canton => setCanton(canton)}
            />
          </Grid>
          <Grid item xs={3}>
            <h4>Distrito</h4>
            <Field
              name="distrito"
              data={distritos}
              component={PlnDropdown}
              onChange={dist => {
                getJRV(dist);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <h4>JRV</h4>
            <Field
              name="jrv"
              data={jrv}
              component={PlnDropdown}
              onChange={jrvItem => getJRVSelected(jrvItem)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];
    return actions;
  };

  if (!Lodash.isEmpty(provincias)) {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <h3>CONSULTA PÚBLICA DE REGISTROS ELECTORALES</h3>
        <h4 className={classes.subtitle}>
          Fiscales Generales
        </h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formik => (
            <>
              {renderForm()}
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={doSearch(formik.values)}
                    style={{ marginTop: 10 }}
                  >
                    BUSCAR
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>

        {showResults &&
          (!isLoading ? (
            <MacroList
              dataEmpty={isDataEmpty}
              listName=""
              uiStylesClasses={classes}
              btnConfig={{}}
              actionConfig={setActions}
              tableHeads={heads}
              tableBody={filteredItems}
              displayCopyright={false}
              isCrudAvailable={false}
            />
          ) : (
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center' }}
              >
                <CircularProgress
                  style={{
                    width: '28px',
                    height: '24px',
                  }}
                />
              </Grid>
            </Grid>
          ))}
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    );
  }
  return '';
};

REFiscalesGenerales.propTypes = {};

export default withStyles(style)(REFiscalesGenerales);
