import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
// import { Provider } from 'react-redux';
import { theme as t } from './Core/Theme/theme';
// import store from './Redux';
import Layout from './Containers/Layout/Layout';
import AppProvider from './Core/Context/AppProvider';

const App = () => {
  return (
    <AppProvider>
      <MuiThemeProvider theme={t}>
        <Layout />
      </MuiThemeProvider>
    </AppProvider>
  );
};

export default App;
