import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useJwt } from 'react-jwt';

import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';

import { tableHeaders } from './data';
import MacroList from '../MacroList/MacroList';
import Filters from '../../Components/Filters/RegistrosElectFilter';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';

import Storage from '../../Storage/Storage';
import miembrosMesaApi from '../../Core/Api/miembrosMesaApi';

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const MiembrosMesa = () => {
  const classes = useStyles();
  const history = useHistory();
  const tokenStorage = Storage.instance;
  // const filteredItems = [];

  const { decodedToken } = useJwt(tokenStorage.getToken());

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]); // used for filtering
  const [isDataEmpty, setDataEmpty] = useState(true);
  const [filters, setFilters] = useState({
    provincia: '',
    canton: '',
    distrito: '',
    cedula: '',
    primerApellido: '',
    segundoApellido: '',
    nombre: '',
  });
  const [open, setOpenDialog] = React.useState(false);
  const [dataRemove, setDataRemove] = React.useState(null);
  const [isRemoved, setIsRemoved] = useState(false); // used on remove modal
  const [removedMsj, setRemoveMsj] = useState(
    'El registro se eliminará permanentemente. ¿Desea proceder?',
  );
  const [hiddenBtn, setHiddenBtn] = useState(false);

  const getFilterKeys = Object.getOwnPropertyNames(filters);
  const TIMING_ON_SEARCH = 900;

  /**
   * Filter Items
   */
  const applyFilters = async () => {
    const activeFilters = getFilterKeys.filter(
      filter => filters[filter] !== '',
    );

    setDataEmpty(true);

    if (activeFilters.length > 0) {
      // FITLRO FUNCIONAL CON UN INPUT: nombre (DO NOT REMOVED YET)
      /* const result = filteredItems.filter(_item => {
        let currentFilter = _item.nombre;
        if (typeof currentFilter === 'number')
          currentFilter = currentFilter.toString();

        return currentFilter
          .toLowerCase()
          .includes(filters.nombre.toLowerCase());
      });

      setDataEmpty(!result.length > 0);

      if (result.length > 0) setFilteredItems(result); */

      const dataFiltered = activeFilters.map(myFilter => {
        const result = items.filter(_item => {
          let currentFilter = _item[myFilter];
          if (typeof currentFilter === 'number')
            currentFilter = currentFilter.toString();

          return currentFilter
            .toLowerCase()
            .includes(filters[myFilter].toLowerCase());
        });

        setDataEmpty(!result.length > 0);
        if (result.length > 0) setFilteredItems(result);
        // console.log(result);
        return result;
      });

      // console.log(dataFiltered);
    } else {
      setFilteredItems(items);
      setDataEmpty(false);
    }
  };

  /**
   * Get Members when the page loads
   */
  const getAllMembers = async () => {
    let itemsList = [];

    setDataEmpty(false);

    try {
      if (decodedToken) {
        const info = await miembrosMesaApi.getAllMiembrosMesa(
          decodedToken.num_cedula,
        );

        const data = info.data.response;

        if (data instanceof Array && info.status === 200) {
          itemsList = data.map(member => {
            return {
              provincia: member.des_nombre_p,
              canton: member.des_nombre_c,
              distrito: member.des_nombre_d,
              jrv: member.cod_mesa,
              puesto: member.des_puesto,
              cedula: member.num_cedula,
              primerApellido: member.des_apell1,
              segundoApellido: member.des_apell2,
              nombre: member.des_nombre,
              id: member.num_linea,
            };
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }

    setDataEmpty(itemsList.length === 0);
    setItems(itemsList);
    setFilteredItems(itemsList);
  };

  /**
   * Remove a member selected by user
   */
  const removeMemberByNum = async () => {
    const numLinea = dataRemove.id;

    if (numLinea && numLinea > 0) {
      setIsRemoved(true);

      await miembrosMesaApi
        .deleteMember(numLinea)
        .then(_response => {
          const info = _response.data.response;

          if (info === 1 && _response.status === 200) {
            setIsRemoved(false);
            setHiddenBtn(true);
            setRemoveMsj(
              'Miembro de Mesa eliminado correctamente.',
            );
            setTimeout(() => {
              setOpenDialog(false);
              window.location.href = '/miembros-mesa/';
            }, 3000);
          } else {
            setHiddenBtn(false);
            setRemoveMsj(
              'La operación no se pudo completar. Intente de nuevo.',
            );
          }
        });
    }
  };

  useEffect(() => {
    async function fetchListAPI() {
      await getAllMembers();
    }

    fetchListAPI();
  }, [decodedToken]);

  useEffect(() => {
    async function proceedFilters() {
      await applyFilters();
    }

    // if (filteredItems.length > 0) proceedFilters();

    const timeoutId = setTimeout(() => {
      if (filteredItems.length > 0) proceedFilters();
    }, TIMING_ON_SEARCH);
    return () => clearTimeout(timeoutId);
  }, [filters]);

  /**
   * List all filters required on the page list
   */
  const listFilters = () => {
    return [
      {
        label: 'Provincia:',
        action: async ev => {
          const updatedValue = {
            provincia: ev.currentTarget.value,
          };

          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.provincia,
        inputId: 'filter-provincia',
        responsiveSize: {
          xs: 6,
          sm: 3,
        },
      },
      {
        label: 'Cantón:',
        action: async ev => {
          const updatedValue = {
            canton: ev.currentTarget.value,
          };
          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.canton,
        inputId: 'filter-canton',
        responsiveSize: {
          xs: 6,
          sm: 3,
        },
      },
      {
        label: 'Distrito:',
        action: async ev => {
          const updatedValue = {
            distrito: ev.currentTarget.value,
          };
          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.distrito,
        inputId: 'filter-distrito',
        responsiveSize: {
          xs: 6,
          sm: 3,
        },
      },
      {
        label: '# Cédula:',
        action: async ev => {
          const updatedValue = {
            cedula: ev.currentTarget.value,
          };

          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.cedula,
        inputId: 'filter-cedula',
        responsiveSize: {
          xs: 6,
          sm: 3,
        },
      },
      {
        label: '1er Apellido:',
        action: async ev => {
          const updatedValue = {
            primerApellido: ev.currentTarget.value,
          };
          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.primerApellido,
        inputId: 'filter-first',
        responsiveSize: {
          xs: 6,
          sm: 3,
        },
      },
      {
        label: '2do Apellido:',
        action: async ev => {
          const updatedValue = {
            segundoApellido: ev.currentTarget.value,
          };
          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.segundoApellido,
        inputId: 'filter-last',
        responsiveSize: {
          xs: 12,
          sm: 3,
        },
      },
      {
        label: 'Nombre:',
        action: async ev => {
          const updatedValue = {
            nombre: ev.currentTarget.value,
          };
          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.nombre,
        inputId: 'filter-name',
        responsiveSize: {
          xs: 12,
          sm: 3,
        },
      },
    ];
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    const actionEdit = {
      aria: 'Edit Miembro Mesa',
      key: 'edit',
      showAction: true,
      icon: (
        <EditIcon
          fontSize="inherit"
          style={{ color: '#84BF48' }}
        />
      ),
      clickHandler: _item => {
        window.location.href = `/miembros-mesa/edit/${_item.id}`;
      },
    };

    actions.push(actionEdit);

    const actionRemove = {
      aria: 'Eliminar Miembro Mesa',
      key: 'remove',
      showAction: true,
      icon: (
        <DeleteSharpIcon
          fontSize="inherit"
          style={{ color: '#cc0000' }}
        />
      ),
      clickHandler: _item => {
        setDataRemove(_item);
        setOpenDialog(true);
      },
    };

    actions.push(actionRemove);
    return actions;
  };

  return (
    <React.Fragment>
      <MacroList
        filters={<Filters config={listFilters()} />}
        dataEmpty={isDataEmpty}
        listName="Lista de Miembros de Mesa"
        uiStylesClasses={classes}
        btnConfig={{
          name: '+ Agregar',
          event: () => history.push('/miembros-mesa/add'),
        }}
        actionConfig={setActions}
        tableHeads={tableHeaders}
        tableBody={filteredItems}
      />
      <PLNDialog
        open={open}
        onClose={() => {}}
        title="Eliminar Miembro de Mesa"
        bodymessage={removedMsj}
        buttons={
          hiddenBtn
            ? []
            : [
                {
                  actionName: 'cancel',
                  color: 'simple',
                  title: 'Cerrar',
                  clickHander: () => {
                    setOpenDialog(!open);
                  },
                },
                {
                  actionName: 'delete',
                  disabled: isRemoved,
                  color: 'remove',
                  title: isRemoved ? (
                    <CircularProgress
                      style={{
                        width: '28px',
                        height: '24px',
                      }}
                    />
                  ) : (
                    'Eliminar'
                  ),
                  clickHander: removeMemberByNum,
                },
              ]
        }
      />
    </React.Fragment>
  );
};

export default MiembrosMesa;
