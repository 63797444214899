/* eslint-disable max-len */
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getReporteResultados001 = (
  codProvincia,
  codCanton,
  codDistrito,
) => {
  return axiosInstance.get(
    `adjudicacionPuestos/getReporteResultados001/${codProvincia}/${codCanton}/${codDistrito}`,
  );
};

const getReporteResultados002 = (
  codProvincia,
  codCanton,
  codMovimiento,
) => {
  return axiosInstance.get(
    `adjudicacionPuestos/getReporteResultados002/${codProvincia}/${codCanton}/${codMovimiento}`,
  );
};

const getReporteResultados003 = (
  codProvincia,
  codMovimiento,
) => {
  const url = [
    `adjudicacionPuestos/getReporteResultados003/${codProvincia}/${codMovimiento}`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados004 = (
  codMovimiento,
  codPuesto,
) => {
  const url = [
    `adjudicacionPuestos/getReporteResultados004/${codMovimiento}/${codPuesto}`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados005 = () => {
  const url = [
    `adjudicacionPuestos/getReporteResultados005`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados006 = codSector => {
  const url = [
    `adjudicacionPuestos/getReporteResultados006/${codSector}`,
  ];

  return axios.get(url.join('&'));
};

export default {
  getReporteResultados001,
  getReporteResultados002,
  getReporteResultados003,
  getReporteResultados004,
  getReporteResultados005,
  getReporteResultados006,
};
