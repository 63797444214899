/* eslint-disable max-len */
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getReporteResultados001 = (
  codProvincia,
  codCanton,
  codDistrito,
  junta,
) => {
  return axiosInstance.get(
    `reporteResultados/getReporteResultados001/${codProvincia}/${codCanton}/${codDistrito}/${junta}`,
  );
};

const getReporteResultados002 = (
  codProvincia,
  codCanton,
  codDistrito,
  numero,
) => {
  return axiosInstance.get(
    `reporteResultados/getReporteResultados002/${codProvincia}/${codCanton}/${codDistrito}/${numero}`,
  );
};

const getDetalleVotacion = codPapeleta => {
  const url = [
    `reporteResultados/getDetalleVotacion/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados003 = (
  codProvincia,
  codCanton,
  codMovimiento,
  numero,
) => {
  const url = [
    `reporteResultados/getReporteResultados003/${codProvincia}/${codCanton}/${codMovimiento}/${numero}`,
  ];

  return axios.get(url.join('&'));
};

const getDetalleVotacionCantonales = codPapeleta => {
  const url = [
    `reporteResultados/getDetalleVotacionCantonales/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados004 = (
  codProvincia,
  codMovimiento,
  numero,
) => {
  const url = [
    `reporteResultados/getReporteResultados004/${codProvincia}/${codMovimiento}/${numero}`,
  ];

  return axios.get(url.join('&'));
};

const getDetalleVotacionProvinciales = codPapeleta => {
  const url = [
    `reporteResultados/getDetalleVotacionProvinciales/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados005 = (
  codMovimiento,
  codPuesto,
) => {
  const url = [
    `reporteResultados/getReporteResultados005/${codMovimiento}/${codPuesto}`,
  ];

  return axios.get(url.join('&'));
};

const getDetalleVotacionNacionales = codPapeleta => {
  const url = [
    `reporteResultados/getDetalleVotacionNacionales/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados006 = () => {
  const url = [`reporteResultados/getReporteResultados006`];

  return axios.get(url.join('&'));
};

const getDetalleVotacionPresidente = codPapeleta => {
  const url = [
    `reporteResultados/getDetalleVotacionPresidente/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

const getReporteResultados007 = (codSector, numero) => {
  const url = [
    `reporteResultados/getReporteResultados007/${codSector}/${numero}`,
  ];

  return axios.get(url.join('&'));
};

const getDetalleVotacionSectores = codPapeleta => {
  const url = [
    `reporteResultados/getDetalleVotacionSectores/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

export default {
  getReporteResultados001,
  getReporteResultados002,
  getDetalleVotacion,
  getReporteResultados003,
  getDetalleVotacionCantonales,
  getReporteResultados004,
  getDetalleVotacionProvinciales,
  getReporteResultados005,
  getDetalleVotacionNacionales,
  getReporteResultados006,
  getDetalleVotacionPresidente,
  getReporteResultados007,
  getDetalleVotacionSectores,
};
