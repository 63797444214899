function createData(
  consecutivo,
  numeroDeposito,
  monto,
  fecha,
  observacion,
  cedula,
  depositante,
  last,
) {
  return {
    consecutivo,
    numeroDeposito,
    monto,
    fecha,
    observacion,
    cedula,
    depositante,
    last,
  };
}

export const tableHeaders = [
  {
    name: 'Número Depósito',
  },
  {
    name: 'Monto',
  },
  {
    name: 'Fecha',
  },
  {
    name: 'Tipo Depósito',
  },
  {
    name: 'Ced. Dep',
  },
  {
    name: 'Depositante',
  },
];

export const dataRows = [
  createData(
    1976,
    '178698521135',
    '550,000.00',
    '23/02/2025',
    'Depósito para Inscripciones',
    '100230628',
    'Calvo Solorzano Marta',
    'A',
  ),
];
