/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { PlnDropdown } from '../../Components/Input';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Consts from '../../Core/Consts/Consts';
import Copyright from '../Copyright/Copyright';

import {
  useProvincias,
  useMovimientos,
} from '../../Core/Api/locationApi';

import reporteResultados from '../../Core/Api/reporteResultados';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

function createData(_data) {
  const filterPuesto = Consts.puestosNacionales.filter(
    status => {
      return status.codigo === _data.num_puesto;
    },
  );
  return {
    movimiento: _data.des,
    puesto: filterPuesto[0].descripcion,
    candidato: _data.num_cedula,
    nombre: _data.des_nombre_can,
    votos: _data.can_votos,
    cod_papeleta: _data.cod_papeleta,
  };
}

const EVPapeletasNacionales = props => {
  const { classes } = props;
  const { data } = useProvincias();
  const listMovimientos = useMovimientos();

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Opciones Seleccionadas
  const [sMovimiento, setSMovimiento] = useState(9);
  // eslint-disable-next-line no-unused-vars
  const [sPuesto, setSPuesto] = useState(9);

  // INFORMACION DEL DETALLE DE LA PAPELETA
  const [rowSelected, setRowSelected] = useState({
    movimiento: '',
    puesto: '',
    cedula: '',
    propietario: '',
  });

  const [dataVotosByJRV, setDataVotosByJRV] = useState([]);
  const [isDataEmpty, setDataEmpty] = useState(false);

  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', '', ''],
  ]);
  const [currentDate, setCurrentDate] = useState('');

  const [openPapeleta, setOpenPapeleta] = useState(false);
  const handleClose = () => {
    setOpenPapeleta(false);
  };

  const heads = [
    {
      name: 'Movimiento',
    },
    {
      name: 'Puesto',
    },
    {
      name: 'Cédula',
    },
    {
      name: 'Nombre Propietario(a)',
    },
    {
      name: 'Votos Recibidos',
    },
  ];

  // Lista de: Provincias, Movimientos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      movimiento: 9,
      puesto: 9,
    }),
  );

  // eslint-disable-next-line prefer-const
  let startMovimientos = [
    { itemLabel: '- SELECCIONE EL MOVIMIENTO -', value: 9 },
  ];
  const [movimientos, setMovimientos] = useState(
    startMovimientos,
  );

  const [puestos, setPuestos] = useState([
    {
      itemLabel: '- SELECCIONE EL PUESTO -',
      value: 9,
    },
    { itemLabel: 'PRESIDENTE', value: 1 },
    { itemLabel: 'VICEPRESIDENTE', value: 2 },
    { itemLabel: 'SECRETARIO GENERAL', value: 3 },
  ]);

  useEffect(() => {
    setMovimientos(
      Lodash.get(
        listMovimientos,
        ['data', 'frmOptions'],
        [
          {
            itemLabel: '- SELECCIONE EL MOVIMIENTO -',
            value: 9,
          },
        ],
      ),
    );
  }, [listMovimientos]);

  const getTimeStamp = someDate => {
    const now = new Date(someDate);
    return `${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }`;
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  const formatDate = date => {
    const dateParts = date.split('/');
    const month = dateParts[1];
    const day = dateParts[0];
    const year = dateParts[2];

    return [day, month, year].join('/');
  };

  const validationSchema = Yup.object({});

  const setMovimiento = d => {
    setPuestos([
      {
        itemLabel: '- SELECCIONE EL PUESTO -',
        value: 9,
      },
      { itemLabel: 'PRESIDENTE', value: 1 },
      { itemLabel: 'VICEPRESIDENTE', value: 2 },
      { itemLabel: 'SECRETARIO GENERAL', value: 3 },
    ]);

    initialValues.movimiento = d;
    initialValues.puesto = 9;

    if (d !== 9) {
      setSMovimiento(
        Lodash.find(movimientos, {
          value: d,
        }),
      );
    } else {
      setSMovimiento(
        Lodash.find(movimientos, {
          value: 9,
        }),
      );
    }
  };

  const setPuesto = d => {
    initialValues.puesto = d;
    if (d !== 9) {
      initialValues.movimiento = sMovimiento.value
        ? sMovimiento.value
        : 9;
      setSPuesto(
        Lodash.find(puestos, {
          value: d,
        }),
      );
    } else {
      setSPuesto(
        Lodash.find(puestos, {
          value: 9,
        }),
      );
    }
  };

  const doSearch = values => () => {
    setShowResults(true);
    setIsLoading(true);
    const { movimiento, puesto } = values;
    const codMovimiento =
      movimiento === 9 ? -1 : movimiento;
    const codPuesto = puesto === 9 ? -1 : puesto;

    reporteResultados
      .getReporteResultados005(codMovimiento, codPuesto)
      .then(result => {
        setIsLoading(false);
        const info = Lodash.get(
          result,
          ['data', 'response'],
          [],
        );
        const currDate = Lodash.get(
          result,
          ['data', 'currentDate'],
          [],
        );

        const cDate = formatDate(
          new Date(currDate).toLocaleDateString(
            'es-ES',
            options,
          ),
        );
        const cHora = getTimeStamp(currDate);

        if (info instanceof Object && info.length > 0) {
          const rowItems = info.map(row => {
            return createData(row);
          });
          setFilteredItems(rowItems);
          setCurrentDate(
            `Fecha: ${cDate} | Hora: ${cHora}`,
          );
          setDataEmpty(false);
        } else {
          setFilteredItems([]);
          setDataEmpty(true);
        }
      });
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h4>Movimiento</h4>
            <Field
              name="movimiento"
              data={movimientos}
              component={PlnDropdown}
              onChange={movimiento =>
                setMovimiento(movimiento)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <h4>Puesto</h4>
            <Field
              name="puesto"
              data={puestos}
              component={PlnDropdown}
              onChange={puesto => setPuesto(puesto)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const consultar = (
    movimiento,
    puesto,
    cedula,
    propietario,
    codPapeleta,
  ) => {
    const newRow = {
      ...rowSelected,
      movimiento,
      puesto,
      cedula,
      propietario,
    };

    reporteResultados
      .getDetalleVotacionNacionales(codPapeleta)
      .then(result => {
        setRowSelected(newRow);
        const votosByJRV = Lodash.get(
          result,
          ['data', 'response', 'votosByJRV'],
          [],
        );
        const currDate = Lodash.get(
          result,
          ['data', 'currentDate'],
          [],
        );

        const cDate = formatDate(
          new Date(currDate).toLocaleDateString(
            'es-ES',
            options,
          ),
        );
        const cHora = getTimeStamp(currDate);

        setDataVotosByJRV(votosByJRV);
        setCurrentDate(`Fecha: ${cDate} | Hora: ${cHora}`);
        setOpenPapeleta(true);
      });
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    const actionConsultar = {
      aria: 'Consultar',
      key: 'consultar',
      showAction: true,
      icon: (
        <VisibilityIcon
          fontSize="default"
          style={{ color: '#008945' }}
        />
      ),
      clickHandler: _item => {
        consultar(
          _item.movimiento,
          _item.puesto,
          _item.candidato,
          _item.nombre,
          _item.cod_papeleta,
        );
      },
    };

    actions.push(actionConsultar);

    return actions;
  };

  const renderTable = () => {
    const list = dataVotosByJRV;
    let total = 0;
    for (let i = 0; i < list.length; i++) {
      total += list[i].can_votos;
    }
    return (
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>JRV</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Votos Obtenidos</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(l => (
              <TableRow key={`votoBy-${l.cod_junta}`}>
                <TableCell align="center">
                  {l.cod_junta}
                </TableCell>
                <TableCell align="center">
                  {l.can_votos}
                </TableCell>
              </TableRow>
            ))}
            <TableRow style={{ backgroundColor: '#eee' }}>
              <TableCell align="center">
                <strong>Total</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{total}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h3>REPORTE DE RESULTADOS</h3>
      <h4 className={classes.subtitle}>
        Papeletas Movimientos Nacionales
      </h4>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {formik => (
          <>
            {renderForm()}
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'right' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={doSearch(formik.values)}
                  style={{ marginTop: 10 }}
                >
                  BUSCAR
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>

      {showResults &&
        (!isLoading ? (
          <>
            <MacroList
              dataEmpty={isDataEmpty}
              listName={currentDate}
              uiStylesClasses={classes}
              btnConfig={{
                name: '+ Registro',
                event: () =>
                  (window.location.href = `/nacionales/add`),
              }}
              actionConfig={setActions}
              tableHeads={heads}
              tableBody={filteredItems}
              displayCopyright={false}
              isCrudAvailable={false}
            />
            <strong
              style={{
                color: '#000',
                marginTop: '-50px',
                display: 'block',
              }}
            >
              Nota: En caso de papeletas únicas no se
              consigna votación alguna.
            </strong>
          </>
        ) : (
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{ textAlign: 'center' }}
            >
              <CircularProgress
                style={{
                  width: '28px',
                  height: '24px',
                }}
              />
            </Grid>
          </Grid>
        ))}
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="lg"
        open={openPapeleta}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Button
          onClick={() => {
            window.print();
          }}
          variant="contained"
          color="primary"
          className={classes.btnPrint}
        >
          Imprimir
        </Button>
        <DialogTitle
          id="alert-dialog-slide-title"
          color="warning"
          style={{ textAlign: 'center' }}
        >
          <span style={{ color: '#008945' }}>
            <strong>
              Detalle Papeleta Movimiento Nacional
            </strong>
          </span>
          <p style={{ fontSize: 14 }}>{currentDate}</p>
        </DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Movimiento</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Puesto</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Cédula</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Nombre Propietario(a)</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  {rowSelected.movimiento}
                </TableCell>
                <TableCell align="center">
                  {rowSelected.puesto}
                </TableCell>
                <TableCell align="center">
                  {rowSelected.cedula}
                </TableCell>
                <TableCell align="center">
                  {rowSelected.propietario}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {renderTable()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            className={classes.btnActions}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

EVPapeletasNacionales.propTypes = {};

export default withStyles(style)(EVPapeletasNacionales);
