/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Grid, Button } from '@material-ui/core';
import { PlnDropdown } from '../../Components/Input';
import style from './styles';

import {
  useProvincias,
  getCantonsByProvince,
  getDistritoBy,
  getCantidadDelegados,
  useMovimientos,
  useSectores,
} from '../../Core/Api/locationApi';

const Formulario = props => {
  const { classes, match } = props;
  const { typeForm } = match.params;
  const { data } = useProvincias();
  const listMovimientos = useMovimientos();
  const listSectores = useSectores();

  const [showPreview, setShowPreview] = useState(false);
  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState(9);
  const [sCanton, setSCanton] = useState(999);
  const [sDistrito, setSDistrito] = useState(999);
  const [sMovimiento, setSMovimiento] = useState(9);
  const [sPuesto, setSPuesto] = useState(9);

  // const [pmpnProvincia, setPmpnProvincia] = useState(false);
  // const [isProvincial, setIsProvincial] = useState(false);

  const [numDelegados, setNumDelegados] = useState(0);

  // PDF Document
  const [pdfSubtitle, setPdfSubtitle] = useState('');
  const [pdfPuesto, setPdfPuesto] = useState('');

  useEffect(() => {
    if (typeForm === 'papeletas-distritales') {
      setPdfSubtitle('Asambleas Distritales');
    }
    if (typeForm === 'presidente-sectores') {
      setPdfSubtitle('ORGANIZACIÓN SECTORIAL');
    }
    if (typeForm === 'hoja-informacion') {
      setPdfSubtitle('TRIBUNAL DE ELECCIONES INTERNAS');
    }
  }, [typeForm]);

  // Lista de: Provincias, Cantones, Distritos y Movimientos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      canton: 999,
      distrito: 999,
      movimiento: 9,
      puesto: 9,
      sector: 9,
    }),
  );
  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );
  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN-', value: 999 },
  ]);
  const [distritos, setDistritos] = useState([
    { itemLabel: '- SELECCIONE EL DISTRITO-', value: 999 },
  ]);
  let startMovimientos = [
    { itemLabel: '- SELECCIONE EL MOVIMIENTO -', value: 9 },
  ];
  if (typeForm === 'papeletas-movimientos-nacionales') {
    startMovimientos = [
      {
        itemLabel: '- SELECCIONE EL MOVIMIENTO -',
        value: 9,
      },
      { itemLabel: 'MUJERES', value: 1 },
      { itemLabel: 'JUVENTUD', value: 2 },
      { itemLabel: 'COOPERATIVO', value: 3 },
      { itemLabel: 'TRABAJADORES', value: 4 },
    ];
  }

  const [movimientos, setMovimientos] = useState(
    startMovimientos,
  );

  /*
  const movimientos = Lodash.get(
    listMovimientos,
    ['data', 'frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE EL MOVIMIENTO -',
        value: 9,
      },
    ],
  );
  */

  const sectores = Lodash.get(
    listSectores,
    ['data', 'frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE EL SECTOR -',
        value: 9,
      },
    ],
  );

  const [puestos, setPuestos] = useState([
    { itemLabel: '- SELECCIONE EL PUESTO -', value: 9 },
  ]);

  const validationSchema = Yup.object({});

  const tableHeaderTitle = [
    'PRIMER LUGAR',
    'SEGUNDO LUGAR',
    'TERCER LUGAR',
    'CUARTO LUGAR',
    'QUINTO LUGAR',
    'SEXTO LUGAR',
    'SÉPTIMO LUGAR',
    'OCTAVO LUGAR',
    'NOVENO LUGAR',
  ];

  const tableHeaderTitleCE = [
    'PARA PRESIDENTE(A)',
    'PARA VICEPRESIDENTE(A)',
    'PARA SECRETARIO(A)',
    'PARA SUBSECRETARIO(A)',
    'PARA TESORERO(A)',
    'PARA SUBTESORERO(A)',
  ];

  const tableHeaderTitleCF = ['PROPIETARIO'];

  const doc = new jsPDF('p', 'px', 'letter');

  const setProvincia = p => {
    setShowPreview(false);
    if (typeForm === 'papeletas-movimientos-cantonales') {
      initialValues.movimiento = 9;
      initialValues.canton = 999;
      setMovimientos([
        {
          itemLabel: '- SELECCIONE EL MOVIMIENTO -',
          value: 9,
        },
      ]);
      setSMovimiento(
        Lodash.find(movimientos, {
          value: 9,
        }),
      );
    }

    if (typeForm === 'papeletas-movimientos-provinciales') {
      initialValues.provincia = p;
      initialValues.movimiento = 9;
      setSProvincia(
        Lodash.find(provincias, {
          value: p,
        }),
      );
      if (p !== 9) {
        setMovimientos(
          Lodash.get(
            listMovimientos,
            ['data', 'frmOptions'],
            [
              {
                itemLabel: '- SELECCIONE EL MOVIMIENTO -',
                value: 9,
              },
            ],
          ),
        );
      } else {
        setMovimientos([
          {
            itemLabel: '- SELECCIONE EL MOVIMIENTO -',
            value: 9,
          },
        ]);
      }
    } else {
      initialValues.provincia = p;
      initialValues.canton = 999;
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
      initialValues.distrito = 999;
      if (p !== 9) {
        getCantonsByProvince(p).then(result => {
          if (result.status === 200) {
            const cantonsList = Lodash.get(result, [
              'data',
              'response',
            ]);

            const clist = cantonsList.map(
              ({ cod_canton, des_nombre_c }) => {
                return {
                  itemLabel: des_nombre_c,
                  value: cod_canton,
                };
              },
            );
            clist.unshift({
              itemLabel: '- SELECCIONE EL CANTÓN-',
              value: 999,
            });

            setSProvincia(
              Lodash.find(provincias, {
                value: p,
              }),
            );
            setCantones(clist);
          }
        });
      } else {
        setCantones([
          {
            itemLabel: '- SELECCIONE EL CANTÓN-',
            value: 999,
          },
        ]);
        setDistritos([
          {
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          },
        ]);
        setMovimientos([
          {
            itemLabel: '- SELECCIONE EL MOVIMIENTO -',
            value: 9,
          },
        ]);
      }
    }
  };

  const setCanton = c => {
    setShowPreview(false);

    initialValues.distrito = 999;
    initialValues.provincia = sProvincia.value;
    initialValues.canton = c;
    initialValues.movimiento = 9;

    if (c !== 999) {
      if (typeForm === 'papeletas-movimientos-cantonales') {
        setMovimientos(
          Lodash.get(
            listMovimientos,
            ['data', 'frmOptions'],
            [
              {
                itemLabel: '- SELECCIONE EL MOVIMIENTO -',
                value: 9,
              },
            ],
          ),
        );
        setSMovimiento(
          Lodash.find(movimientos, {
            value: 9,
          }),
        );
      }
      getDistritoBy(sProvincia.value, c).then(result => {
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);
          const dlist = distritosList.map(
            ({ cod_dist_ad, des_nombre_ad }) => {
              return {
                itemLabel: des_nombre_ad,
                value: cod_dist_ad,
              };
            },
          );
          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });
    } else {
      setMovimientos([
        {
          itemLabel: '- SELECCIONE EL MOVIMIENTO -',
          value: 9,
        },
      ]);
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
    }
  };

  const setDistrito = d => {
    initialValues.provincia = sProvincia.value;
    initialValues.canton = sCanton.value;
    initialValues.distrito = d;
    if (d !== 999) {
      setShowPreview(true);
      setSDistrito(
        Lodash.find(distritos, {
          value: d,
        }),
      );

      getCantidadDelegados(
        sProvincia.value,
        sCanton.value,
        d,
      ).then(response => {
        const cantDelegados = Lodash.get(
          response,
          ['data', 0, 'numDelegados'],
          0,
        );
        setNumDelegados(cantDelegados);
      });
    } else {
      setShowPreview(false);
      setSDistrito({ itemLabel: '', value: 0 });
    }
  };

  const setMovimiento = d => {
    if (d === 1) {
      setPdfSubtitle('Mov. Mujeres');
    } else if (d === 2) {
      setPdfSubtitle('Mov. Juventud');
    } else if (d === 3) {
      setPdfSubtitle('Mov. Cooperativo');
    } else if (d === 4) {
      setPdfSubtitle('Mov. Trabajadores');
    }

    if (typeForm === 'papeletas-movimientos-nacionales') {
      setShowPreview(false);
      initialValues.movimiento = d;
      if (d !== 9) {
        setSMovimiento(
          Lodash.find(movimientos, {
            value: d,
          }),
        );

        setPuestos([
          {
            itemLabel: '- SELECCIONE EL PUESTO -',
            value: 9,
          },
          { itemLabel: 'PRESIDENTE', value: 1 },
          { itemLabel: 'VICEPRESIDENTE', value: 2 },
          { itemLabel: 'SECRETARIO GENERAL', value: 3 },
        ]);
        initialValues.puesto = 9;
      } else {
        initialValues.puesto = 9;
        setShowPreview(false);
        setPuestos([
          {
            itemLabel: '- SELECCIONE EL PUESTO -',
            value: 9,
          },
        ]);
        setSMovimiento({ itemLabel: '', value: 0 });
      }
    } else if (
      typeForm === 'papeletas-movimientos-provinciales'
    ) {
      initialValues.provincia = sProvincia.value;
      initialValues.movimiento = d;
      if (d !== 9) {
        setShowPreview(true);
        // setIsProvincial(true);
        setSMovimiento(
          Lodash.find(movimientos, {
            value: d,
          }),
        );
      } else {
        setShowPreview(false);
        setSMovimiento({ itemLabel: '', value: 0 });
      }
    } else {
      initialValues.provincia = sProvincia.value;
      initialValues.canton = sCanton.value;
      initialValues.movimiento = d;
      if (d !== 9) {
        setShowPreview(true);
        // setIsProvincial(false);
        setSMovimiento(
          Lodash.find(movimientos, {
            value: d,
          }),
        );
      } else {
        setShowPreview(false);
        setSMovimiento({ itemLabel: '', value: 0 });
      }
    }
  };

  const setPuesto = d => {
    /*
    initialValues.provincia = sProvincia.value;
    initialValues.canton = sCanton.value;
    initialValues.movimiento = d;
    */
    // setShowPreview(false);

    if (d !== 9) {
      initialValues.movimiento = sMovimiento.value;
      initialValues.provincia = 9;
      initialValues.puesto = d;
      setSPuesto(
        Lodash.find(puestos, {
          value: d,
        }),
      );

      /*
      if (d === 5) {
        setIsProvincial(true);
        setPmpnProvincia(true);
        setShowPreview(false);
      } else {
        */
      // setIsProvincial(false);
      // setPmpnProvincia(false);
      setShowPreview(true);
      // }

      if (d === 1) {
        setPdfPuesto('PRESIDENTE(A)');
      } else if (d === 2) {
        setPdfPuesto('VICEPRESIDENTE(A)');
      } else if (d === 3) {
        setPdfPuesto('SECRETARIO(A) GENERAL');
      } else if (d === 5) {
        setPdfPuesto('REPRESENTANTE PROVINCIAL');
      }
    } else {
      setShowPreview(false);
      setSPuesto({ itemLabel: '', value: 0 });
    }
  };

  const setSector = d => {
    setShowPreview(true);
    initialValues.sector = d;
    if (d !== 9) {
      if (d === 2) {
        setPdfSubtitle('Sector Profesional');
      } else if (d === 3) {
        setPdfSubtitle('Sector Educativo');
      } else if (d === 4) {
        setPdfSubtitle('Sector Empresarial');
      }
    } else {
      setShowPreview(false);
    }
  };

  const downloadForm = values => {
    // console.log(values);
    return values;
  };

  const getTimeStamp = () => {
    const now = new Date();
    return `${
      now.getMonth() + 1
    }/${now.getDate()}/${now.getFullYear()} ${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }:${
      now.getSeconds() < 10
        ? `0${now.getSeconds()}`
        : now.getSeconds()
    }`;
  };

  const centeredText = (text, y) => {
    const textWidth =
      (doc.getStringUnitWidth(text) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset =
      (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
  };

  const getTitlePage = () => {
    let title = '';
    if (typeForm === 'papeletas-distritales') {
      title = ' PAPELETAS DISTRITALES';
    } else if (
      typeForm === 'papeletas-movimientos-cantonales'
    ) {
      title = ' PAPELETAS DE MOVIMIENTOS CANTONALES';
    } else if (
      typeForm === 'papeletas-movimientos-provinciales'
    ) {
      title = ' PAPELETAS DE MOVIMIENTOS PROVINCIALES';
    } else if (
      typeForm === 'papeletas-movimientos-nacionales'
    ) {
      title = ' PAPELETAS DE MOVIMIENTOS NACIONALES';
    } else if (typeForm === 'presidente-sectores') {
      title = ' PAPELETA PRESIDENCIA DE SECTORES';
    } else if (typeForm === 'papeletas-sectores') {
      title = ' PAPELETA DE SECTORES';
    } else if (typeForm === 'hoja-informacion') {
      title = ' HOJA DE INFORMACIÓN';
    }

    return title;
  };

  const getNotesPDF = () => {
    let notesData = [];

    if (typeForm === 'papeletas-distritales') {
      notesData = [
        [
          // eslint-disable-next-line max-len
          `* No se aceptarán inscripciones con documentación o número de candidatos incompletos.`,
        ],
        [
          // eslint-disable-next-line max-len
          `* El gestor(a) es ahora el representante de la papeleta contenida en este formulario ante el T.E.I., para todos los efectos de su inscripción y tramitación.`,
        ],
        [
          // eslint-disable-next-line max-len
          `* RECUERDE: Las nominas deben ordenarse con alternabilidad de género. Debe haber dentro de los candidatos(as) al menos un 20% personas entre 18 a 35 años de edad.`,
        ],
      ];
    } else if (
      typeForm === 'papeletas-movimientos-cantonales'
    ) {
      notesData = [
        [
          // eslint-disable-next-line max-len
          `* Cada candidato(a) debe contar con los documentos que le acrediten como miembro de este Movimiento.`,
        ],

        [
          // eslint-disable-next-line max-len
          `* El gestor(a) es ahora el representante de la papeleta contenida en este formulario ante el T.E.I., para todos los efectos de su inscripción y tramitación.`,
        ],
        [
          // eslint-disable-next-line max-len
          `* Las nominas deben ordenarse con alternabilidad de género. (Con excepción del Mov. de Mujeres)`,
        ],
      ];
    } else if (
      typeForm === 'papeletas-movimientos-nacionales' ||
      typeForm === 'papeletas-movimientos-provinciales'
    ) {
      notesData = [
        [
          // eslint-disable-next-line max-len
          `* Cada candidato(a) debe contar con los documentos que le acrediten como miembro de este Movimiento. No se aceptarán inscripciones con documentación o número de candidatos incompletos.`,
        ],

        [
          // eslint-disable-next-line max-len
          `* El gestor(a) es ahora el representante de la papeleta contenida en este formulario ante el T.E.I., para todos los efectos de su inscripción y tramitación.`,
        ],
      ];
    } else if (typeForm === 'presidente-sectores') {
      notesData = [
        [
          // eslint-disable-next-line max-len
          `* El gestor(a) es ahora el representante de la papeleta contenida en este formulario ante el T.E.I., para todos los efectos de su inscripción y tramitación.`,
        ],
        [
          // eslint-disable-next-line max-len
          `* No se aceptarán inscripciones con documentación o número de candidatos incompletos.`,
        ],
      ];
    } else if (typeForm === 'papeletas-sectores') {
      notesData = [
        [
          // eslint-disable-next-line max-len
          `* Cada candidato(a) debe contar con los documentos que le acrediten como miembro de este Sector.`,
        ],

        [
          // eslint-disable-next-line max-len
          `* El gestor(a) es ahora el representante de la papeleta contenida en este formulario ante el T.E.I., para todos los efectos de su inscripción y tramitación.`,
        ],
        [
          // eslint-disable-next-line max-len
          `* No se aceptarán inscripciones con documentación o número de candidatos incompletos`,
        ],
      ];
    }

    return notesData;
  };

  const generatePDF = () => {
    // ENCABEZADO DEL DOCUMENTO
    doc.setFont('times', 'bold');
    doc.text(
      20,
      40,
      process.env.REACT_APP_PDF_PARTIDO_REPORTS,
    );
    doc.setFontSize(12);
    doc.text(20, 60, pdfSubtitle);
    doc.setFont('times', 'normal');
    doc.text(
      20,
      80,
      process.env.REACT_APP_PDF_PERIODO_REPORTS,
    );

    doc.setFont('times', 'bold');

    centeredText(
      process.env.REACT_APP_PDF_TITLE_FORMULARIO_REPORTS,
      100,
    );

    doc.setFont('times', 'normal');
    doc.text(20, 120, 'Señores');
    doc.text(
      20,
      130,
      process.env.REACT_APP_PDF_TITLE_TRIBUNAL_REPORTS,
    );

    // TABLA DE OPCIONES SELECCIONADAS EN EL FORMULARIO

    // eslint-disable-next-line max-len
    let paragraph = `Nosotros los abajo firmantes, miembros activos del Partido, de conformidad con lo dispuesto en el Reglamento para las Asambleas Distritales, solicitamos la inscripción de las siguientes candidaturas para participar en la escogencia de las y los delegados distritales para:`;
    if (typeForm === 'papeletas-movimientos-cantonales') {
      // eslint-disable-next-line max-len
      paragraph = `Nosotros los abajo firmantes, miembros activos del Partido, de conformidad con lo dispuesto en el Reglamento para las Asambleas Distritales, Movimientos, solicitamos la inscripción de las siguientes candidaturas para que participen de las Asambleas del ${pdfSubtitle} Liberacionista correspondientes al:`;
    }
    if (
      typeForm === 'papeletas-movimientos-nacionales' ||
      typeForm === 'papeletas-movimientos-provinciales'
    ) {
      // eslint-disable-next-line max-len
      paragraph = `Los suscritos, abajo firmantes, miembros activo del Partido, de conformidad con lo dispuesto en el Reglamento para las Asambleas de Movimientos, solicito la inscripción de las presentes candidaturas para participar en las Asambleas del ${pdfSubtitle} Liberacionista.`;
      if (typeForm === 'papeletas-movimientos-nacionales') {
        if (
          pdfSubtitle === 'Mov. Mujeres' ||
          pdfSubtitle === 'Mov. Juventud'
        ) {
          // eslint-disable-next-line max-len
          paragraph = `El(la) suscrito(a), abajo firmante, miembro activo del Partido, de conformidad con lo dispuesto en el Reglamento para las Asambleas de Movimientos, solicito la inscripción de la presente candidatura para participar en las Asambleas del ${pdfSubtitle} Liberacionista.`;
        }
      }
    }
    if (typeForm === 'presidente-sectores') {
      // eslint-disable-next-line max-len
      paragraph = `El(la) suscrito(a), abajo firmante, miembro activo del Partido, de conformidad con lo dispuesto en el Reglamento para las Asambleas Distritales, Movimientos y Sectores, solicito la inscripción de la siguiente candidatura para participar en la Organización Sectorial.`;
    }
    if (typeForm === 'papeletas-sectores') {
      // eslint-disable-next-line max-len
      paragraph = `Nosotros los(las) abajo firmantes, miembros activos del Partido, de conformidad con lo dispuesto en el Reglamento para las Asambleas de Movimientos y Sectores, solicitamos la inscripción de la presente candidatura para participar por el ${pdfSubtitle}:`;
    }
    let lines = doc.splitTextToSize(paragraph, 415);
    doc.text(20, 140, lines);

    let columns = [
      'Posible N° de Papeleta',
      'Provincia',
      'Cantón',
      'Distrito Administrativo',
    ];

    let typeTable1 = 'grid';
    let showTheHeader = true;
    let baseHeightText = 220;
    let baseHeightTable = 85;

    if (typeForm === 'papeletas-movimientos-cantonales') {
      columns = ['N° Papeleta', 'Provincia', 'Cantón'];
    }
    if (typeForm === 'papeletas-movimientos-nacionales') {
      columns = [''];
      typeTable1 = 'plain';
      showTheHeader = 'never';
      baseHeightText = 180;
      baseHeightTable = 55;
    }
    if (typeForm === 'papeletas-movimientos-provinciales') {
      columns = ['Provincia', 'Nº Papeleta'];
    }
    if (typeForm === 'presidente-sectores') {
      columns = [''];
    }
    if (typeForm === 'papeletas-sectores') {
      columns = ['Posible N° de Papeleta'];
    }

    let datos1 = [
      [
        '',
        sProvincia.itemLabel,
        sCanton.itemLabel,
        sDistrito.itemLabel,
      ],
    ];

    if (typeForm === 'papeletas-movimientos-cantonales') {
      datos1 = [
        ['', sProvincia.itemLabel, sCanton.itemLabel],
      ];
    }
    if (typeForm === 'papeletas-movimientos-nacionales') {
      datos1 = [['']];
    }
    if (typeForm === 'papeletas-movimientos-provinciales') {
      datos1 = [[sProvincia.itemLabel, '']];
    }
    if (typeForm === 'presidente-sectores') {
      datos1 = [['']];
      typeTable1 = 'plain';
      showTheHeader = 'never';
      baseHeightText = 180;
      baseHeightTable = 55;
    }
    if (typeForm === 'papeletas-sectores') {
      datos1 = [['']];
    }

    // TABLA FORMULARIO
    doc.autoTable({
      head: [columns],
      body: datos1,
      theme: typeTable1,
      startY: 170,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        2: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        3: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: showTheHeader,
      margin: { left: 20 },
    });

    // TABLA: CANDIDATOS DELEGADOS DISTRITALES
    doc.setFontSize(12);
    doc.setFont('times', 'normal');
    if (pdfSubtitle === 'Mov. Mujeres') {
      // eslint-disable-next-line max-len
      paragraph = `Declaramos incondicionalmente nuestra adhesión al Partido y a sus principios ideológicos y programáticos y aceptamos en su totalidad las normas ético­-jurídicas que rigen el proceso, así como las decisiones, resoluciones y acuerdos del Tribunal de Elecciones Internas. Igualmente nos comprometemos a contribuir económicamente al mantenimiento del Partido, según lo dispuesto en el Artículo 14 del Estatuto. La nómina de nuestra papeleta es la siguiente:`;
    } else {
      // eslint-disable-next-line max-len
      paragraph = `Declaramos incondicionalmente nuestra adhesión al Partido y a sus principios ideológicos y programáticos y aceptamos en su totalidad las normas ético­-jurídicas que rigen el proceso, así como las decisiones, resoluciones y acuerdos del Tribunal de Elecciones Internas. Igualmente nos comprometemos a contribuir económicamente al mantenimiento del Partido, según lo dispuesto en el Artículo 14 del Estatuto. La nómina de nuestra papeleta, integrada en orden alternativo de género, es la siguiente:`;
    }

    lines = doc.splitTextToSize(paragraph, 425);
    doc.text(20, baseHeightText, lines);

    let dinamicFields = 7;
    let theTitle =
      'CANDIDATOS(AS) DELEGADOS(AS) DISTRITALES';
    if (typeForm === 'papeletas-movimientos-cantonales') {
      theTitle = 'COMITÉ POLÍTICO CANTONAL';
    } else if (
      typeForm === 'papeletas-movimientos-nacionales'
    ) {
      theTitle = `${pdfPuesto} DEL MOVIMIENTO`;

      dinamicFields = 1;
      if (
        sMovimiento.value === 4 ||
        sMovimiento.value === 3
      ) {
        dinamicFields = 3;
      }
    } else if (
      typeForm === 'papeletas-movimientos-provinciales'
    ) {
      theTitle = `REPRESENTANTE PROVINCIAL ANTE LA ASAMBLEA NACIONAL`;
      dinamicFields = 4;
    } else if (typeForm === 'presidente-sectores') {
      theTitle = `PRESIDENTE(A) NACIONAL DE LA ORGANIZACIÓN SECTORIAL`;
      dinamicFields = 1;
    } else if (typeForm === 'papeletas-sectores') {
      theTitle = `REPRESENTANTES AL ORGANO CONSULTIVO NACIONAL`;
      dinamicFields = 3;
    } else {
      dinamicFields = numDelegados;
    }

    if (typeForm !== 'presidente-sectores') {
      doc.setFont('times', 'bold');
      centeredText(theTitle, 280);
    }

    if (
      typeForm === 'papeletas-movimientos-nacionales' ||
      typeForm === 'papeletas-movimientos-provinciales'
    ) {
      doc.setFont('times', 'bold');
      centeredText(theTitle, 280);
    }

    let posY = doc.lastAutoTable.finalY + baseHeightTable;

    for (let n = 0; n < dinamicFields; n++) {
      if (n !== 0) {
        posY = doc.lastAutoTable.finalY + 5;
      }

      const numRow = n + 1;
      const columnsT = ['A'];
      let dataT = [];
      if (
        typeForm === 'presidente-sectores' ||
        typeForm === 'papeletas-movimientos-nacionales'
      ) {
        dataT = [[`${numRow} - ${theTitle}`]];
        if (numRow === 2 || numRow === 3) {
          dataT = [
            [`${numRow} - ${sPuesto.itemLabel} (SUPLENTE)`],
          ];
        }
      } else {
        dataT = [[`${numRow} - ${tableHeaderTitle[n]}`]];
      }
      // posY += 20;
      doc.autoTable({
        head: [columnsT],
        body: dataT,
        theme: 'grid',
        startY: posY,
        styles: { font: 'times', fontStyle: 'bold' },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      const columns2 = [
        'CÉDULA',
        'PRIMER APELLIDO',
        'SEGUNDO APELLIDO',
        'NOMBRE',
      ];
      const datos2 = [['', '', '', '', '']];

      // posY += 15;
      doc.autoTable({
        head: [columns2],
        body: datos2,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          font: 'times',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          2: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          3: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        margin: { left: 20 },
      });

      const columns3 = ['A'];
      const data3 = [
        [
          // eslint-disable-next-line max-len
          `En este acto con mi firma de puño y letra, ratifico mi aceptación como candidato(a). Asimismo autorizo al gestor(a) para que deposite lo correspondiente a mi cuota de membrecía.`,
        ],
      ];

      // posY += 45;
      doc.autoTable({
        head: [columns3],
        body: data3,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times' },
        columnStyles: {
          0: {
            halign: 'left',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      const columns4 = ['', ''];
      const data4 = [
        [
          '                        FIRMA                            ',
          '_____________________________________________',
        ],
      ];

      // posY += 70;
      doc.autoTable({
        head: [columns4],
        body: data4,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times', color: '#000' },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });
    }

    if (typeForm === 'papeletas-distritales') {
      // TABLA: CANDIDATOS COMITÉ EJECUTIVO DISTRITAL
      const columnsT = ['A'];
      const dataT = [
        [
          `CANDIDATOS(AS) PARA EL COMITÉ EJECUTIVO DISTRITAL`,
        ],
      ];

      doc.autoTable({
        head: [columnsT],
        body: dataT,
        theme: 'plain',
        startY: doc.lastAutoTable.finalY + 10,
        styles: {
          font: 'times',
          fontStyle: 'bold',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
            fontSize: 12,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      posY = doc.lastAutoTable.finalY + 5;

      for (
        let n2 = 0;
        n2 < tableHeaderTitleCE.length;
        n2++
      ) {
        if (n2 !== 0) {
          posY = doc.lastAutoTable.finalY + 5;
        }

        const numRow = n2 + 1;
        const columnsT2 = ['A'];
        const dataT2 = [
          [`${numRow} - ${tableHeaderTitleCE[n2]}`],
        ];
        // posY += 20;
        doc.autoTable({
          head: [columnsT2],
          body: dataT2,
          theme: 'grid',
          startY: posY,
          styles: { font: 'times', fontStyle: 'bold' },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          showHead: 'never',
          margin: { left: 20 },
        });

        const columns2 = [
          'CÉDULA',
          'PRIMER APELLIDO',
          'SEGUNDO APELLIDO',
          'NOMBRE',
        ];
        const datos2 = [['', '', '', '', '']];

        // posY += 15;
        doc.autoTable({
          head: [columns2],
          body: datos2,
          theme: 'grid',
          startY: doc.lastAutoTable.finalY,
          styles: {
            overflow: 'linebreak',
            cellWidth: 'wrap',
            font: 'times',
          },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
            1: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
            2: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
            3: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          margin: { left: 20 },
        });

        const columns3 = ['A'];
        const data3 = [
          [
            // eslint-disable-next-line max-len
            `En este acto con mi firma de puño y letra, ratifico mi aceptación como candidato(a). Asimismo autorizo al gestor(a) para que deposite lo correspondiente a mi cuota de membrecía.`,
          ],
        ];

        // posY += 45;
        doc.autoTable({
          head: [columns3],
          body: data3,
          theme: 'grid',
          startY: doc.lastAutoTable.finalY,
          styles: { font: 'times' },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          showHead: 'never',
          margin: { left: 20 },
        });

        const columns4 = ['', ''];
        const data4 = [
          [
            '                        FIRMA                            ',
            '_____________________________________________',
          ],
        ];

        // posY += 70;
        doc.autoTable({
          head: [columns4],
          body: data4,
          theme: 'grid',
          startY: doc.lastAutoTable.finalY,
          styles: { font: 'times', color: '#000' },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
            1: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          showHead: 'never',
          margin: { left: 20 },
        });
      }

      // TABLA: CANDIDATOS FISCALÍA
      const columnsT2 = ['A'];
      const dataT2 = [[`CANDIDATOS(AS) PARA LA FISCALÍA`]];

      doc.autoTable({
        head: [columnsT2],
        body: dataT2,
        theme: 'plain',
        startY: doc.lastAutoTable.finalY + 10,
        styles: {
          font: 'times',
          fontStyle: 'bold',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
            fontSize: 12,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      posY = doc.lastAutoTable.finalY + 5;

      for (
        let n3 = 0;
        n3 < tableHeaderTitleCF.length;
        n3++
      ) {
        if (n3 !== 0) {
          posY = doc.lastAutoTable.finalY + 5;
        }

        const numRow = n3 + 1;
        const columnsT3 = ['A'];
        const dataT3 = [
          [`${numRow} - ${tableHeaderTitleCF[n3]}`],
        ];
        // posY += 20;
        doc.autoTable({
          head: [columnsT3],
          body: dataT3,
          theme: 'grid',
          startY: posY,
          styles: { font: 'times', fontStyle: 'bold' },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          showHead: 'never',
          margin: { left: 20 },
        });

        const columns2 = [
          'CÉDULA',
          'PRIMER APELLIDO',
          'SEGUNDO APELLIDO',
          'NOMBRE',
        ];
        const datos2 = [['', '', '', '', '']];

        // posY += 15;
        doc.autoTable({
          head: [columns2],
          body: datos2,
          theme: 'grid',
          startY: doc.lastAutoTable.finalY,
          styles: {
            overflow: 'linebreak',
            cellWidth: 'wrap',
            font: 'times',
          },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
            1: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
            2: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
            3: {
              halign: 'center',
              fillColor: [255, 255, 255],
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          margin: { left: 20 },
        });

        const columns3 = ['A'];
        const data3 = [
          [
            // eslint-disable-next-line max-len
            `En este acto con mi firma de puño y letra, ratifico mi aceptación como candidato(a). Asimismo autorizo al gestor(a) para que deposite lo correspondiente a mi cuota de membrecía.`,
          ],
        ];

        // posY += 45;
        doc.autoTable({
          head: [columns3],
          body: data3,
          theme: 'grid',
          startY: doc.lastAutoTable.finalY,
          styles: { font: 'times' },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          showHead: 'never',
          margin: { left: 20 },
        });

        const columns4 = ['', ''];
        const data4 = [
          [
            '                        FIRMA                            ',
            '_____________________________________________',
          ],
        ];

        // posY += 70;
        doc.autoTable({
          head: [columns4],
          body: data4,
          theme: 'grid',
          startY: doc.lastAutoTable.finalY,
          styles: { font: 'times', color: '#000' },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
            1: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: 0,
            },
          },
          headStyles: {
            fillColor: [204, 204, 204],
            fontSize: 9,
            textColor: 0,
            halign: 'center',
          },
          showHead: 'never',
          margin: { left: 20 },
        });
      }

      // TABLA: GESTOR DE LA PAPELETA
      const columnsT3 = ['A'];
      const dataT3 = [
        [
          `GESTOR DE LA PAPELETA ANTE EL TRIBUNAL DE ELECCIONES INTERNAS`,
        ],
      ];

      doc.autoTable({
        head: [columnsT3],
        body: dataT3,
        theme: 'plain',
        startY: doc.lastAutoTable.finalY + 10,
        styles: {
          font: 'times',
          fontStyle: 'bold',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
            fontSize: 12,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      posY = doc.lastAutoTable.finalY + 5;

      const columnsT4 = ['A', 'B'];
      const dataT4 = [
        [
          'CÉDULA',
          '                                             ',
        ],
        [
          'NOMBRE COMPLETO',
          '                                             ',
        ],
        [
          'FIRMA',
          '                                             ',
        ],
      ];

      doc.autoTable({
        head: [columnsT4],
        body: dataT4,
        theme: 'grid',
        startY: posY,
        styles: { font: 'times', fontStyle: 'bold' },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      posY = doc.lastAutoTable.finalY + 5;
    }

    // TABLA: NOTAS
    const columnsT5 = ['A'];
    const dataT5 = [[`Notas`]];

    doc.autoTable({
      head: [columnsT5],
      body: dataT5,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsT6 = ['A'];
    const dataT6 = getNotesPDF();

    doc.autoTable({
      head: [columnsT6],
      body: dataT6,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    // TABLA: RECUERDE
    if (typeForm === 'papeletas-sectores') {
      const columnsT7 = ['A'];
      const dataT7 = [[`RECUERDE`]];

      doc.autoTable({
        head: [columnsT7],
        body: dataT7,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: {
          font: 'times',
          fontStyle: 'bold',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
            fontSize: 12,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      const columnsT8 = ['A'];
      const dataT8 = [
        [
          `* Las nominas deben ordenarse con alternabilidad de género.`,
        ],
        [
          // eslint-disable-next-line max-len
          `* Debe haber dentro de los candidatos(as) al menos un 20% personas entre 18 a 35 años de edad.`,
        ],
      ];

      doc.autoTable({
        head: [columnsT8],
        body: dataT8,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: {
          font: 'times',
        },
        columnStyles: {
          0: {
            halign: 'left',
            fillColor: [255, 255, 255],
            textColor: 0,
            fontSize: 10,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'left',
        },
        showHead: 'never',
        margin: { left: 20 },
      });
    }

    // FOOTER DOCUMENT
    const columnsPie = ['A'];
    const dataPie = [
      [
        // eslint-disable-next-line max-len
        `PLNDigital.com ${process.env.REACT_APP_PDF_YEAR_REPORTS} Email: consultas@plndigital.com PLN; Mata Redonda, 125m O. del edificio del M.A.G., Casa Liberacionista "José Figueres Ferrer" Tel: (506)2549­5500. Aptdo:10051­1000 San José. Sitio web desarrollado por Corporación DSI S.A.`,
      ],
    ];
    doc.autoTable({
      head: [columnsPie],
      body: dataPie,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 50,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const fileTS = getTimeStamp();
    doc.save(`formulario-${typeForm}-${fileTS}.pdf`);
  };

  const generateHojaInformacion = () => {
    // ENCABEZADO DEL DOCUMENTO
    doc.setFont('times', 'bold');
    doc.text(
      20,
      40,
      process.env.REACT_APP_PDF_PARTIDO_REPORTS,
    );
    doc.setFontSize(12);
    doc.text(20, 60, pdfSubtitle);
    doc.setFont('times', 'normal');
    doc.text(
      20,
      80,
      process.env.REACT_APP_PDF_PERIODO_REPORTS,
    );

    doc.setFont('times', 'bold');
    centeredText(
      process.env.REACT_APP_PDF_TITLE_HOJA_INFO_REPORTS,
      100,
    );

    doc.setFont('times', 'bold');
    const columnsA = ['CÉDULA', ''];
    const dataA = [['CÉDULA', '']];

    doc.autoTable({
      head: [columnsA],
      body: dataA,
      theme: 'grid',
      startY: 120,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsB = ['NOMBRE', ''];
    const dataB = [['NOMBRE', '']];

    doc.autoTable({
      head: [columnsB],
      body: dataB,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsC = ['DIRECCION', ''];
    const dataC = [['DIRECCIÓN HABITACIÓN', '']];

    doc.autoTable({
      head: [columnsC],
      body: dataC,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsD = [
      'TELEFONO HABITACIÓN',
      'CELULAR',
      'FAX',
      'OTRO',
    ];
    const dataD = [['', '', '', '']];

    doc.autoTable({
      head: [columnsD],
      body: dataD,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'left',
          cellWidth: 100,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        2: {
          halign: 'left',
          cellWidth: 95,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        3: {
          halign: 'left',
          cellWidth: 95,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      margin: { left: 20 },
    });

    const columnsE = ['DIRECCION TRABAJO', ''];
    const dataE = [['DIRECCIÓN TRABAJO', '']];

    doc.autoTable({
      head: [columnsE],
      body: dataE,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsF = ['TELEFONO TRABAJO', 'FAX TRABAJO'];
    const dataF = [['', '']];

    doc.autoTable({
      head: [columnsF],
      body: dataF,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 230,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'left',
          cellWidth: 190,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      margin: { left: 20 },
    });

    const columnsG = [
      'CORREO ELECTRONICO OFICIAL PARA NOTIFICACIONES',
      '',
    ];
    const dataG = [
      [
        'CORREO ELECTRÓNICO OFICIAL PARA NOTIFICACIONES',
        '',
      ],
    ];

    doc.autoTable({
      head: [columnsG],
      body: dataG,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsH = ['H'];
    const dataH = [
      [
        // eslint-disable-next-line max-len
        'En apego a la Ley No 8968 de Protección de la Persona frente al tratamiento de sus datos personales: Autorizo al Partido Liberación Nacional, para que utilice mi información, con el fin de que el PLN pueda mantener contacto con mi persona, ya sea por teléfono o por correo electrónico.',
      ],
    ];

    doc.autoTable({
      head: [columnsH],
      body: dataH,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 420,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsI = ['FIRMA'];
    const dataI = [
      ['____________________________________________'],
    ];

    doc.autoTable({
      head: [columnsI],
      body: dataI,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          cellWidth: 420,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      margin: { left: 20 },
    });

    // TABLA: NOTAS
    const columnsT5 = ['A'];
    const dataT5 = [[`Notas`]];

    doc.autoTable({
      head: [columnsT5],
      body: dataT5,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsT6 = ['A'];
    const dataT6 = [
      [
        // eslint-disable-next-line max-len
        `Para cada gestor(a) y candidato(a) se debe aportar un correo electrónico personal oficial para recibir notificaciones del T.E.I. o del T.S.E. (No se recomienda utilizar el mismo correo electrónico del gestor).`,
      ],
    ];

    doc.autoTable({
      head: [columnsT6],
      body: dataT6,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    // FOOTER DOCUMENT
    const columnsPie = ['A'];
    const dataPie = [
      [
        // eslint-disable-next-line max-len
        `PLNDigital.com ${process.env.REACT_APP_PDF_YEAR_REPORTS} Email: consultas@plndigital.com PLN; Mata Redonda, 125m O. del edificio del M.A.G., Casa Liberacionista "José Figueres Ferrer" Tel: (506)2549­5500. Aptdo:10051­1000 San José. Sitio web desarrollado por Corporación DSI S.A.`,
      ],
    ];
    doc.autoTable({
      head: [columnsPie],
      body: dataPie,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 50,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const fileTS = getTimeStamp();
    doc.save(`formulario-${typeForm}-${fileTS}.pdf`);
  };

  const renderForm = () => {
    let formPage = '';
    if (typeForm === 'papeletas-distritales') {
      formPage = (
        <div className={classes.boxForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={downloadForm}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <h4>Provincia</h4>
                <Field
                  name="provincia"
                  data={provincias}
                  component={PlnDropdown}
                  onChange={provincia =>
                    setProvincia(provincia)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <h4>Cantón</h4>
                <Field
                  name="canton"
                  data={cantones}
                  component={PlnDropdown}
                  onChange={canton => setCanton(canton)}
                />
              </Grid>
              <Grid item xs={4}>
                <h4>Distrito Administrativo</h4>
                <Field
                  name="distrito"
                  data={distritos}
                  component={PlnDropdown}
                  onChange={distrito =>
                    setDistrito(distrito)
                  }
                />
              </Grid>
            </Grid>
          </Formik>
        </div>
      );
    } else if (
      typeForm === 'papeletas-movimientos-cantonales'
    ) {
      formPage = (
        <div className={classes.boxForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={downloadForm}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <h4>Provincia</h4>
                <Field
                  name="provincia"
                  data={provincias}
                  component={PlnDropdown}
                  onChange={provincia =>
                    setProvincia(provincia)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <h4>Cantón</h4>
                <Field
                  name="canton"
                  data={cantones}
                  component={PlnDropdown}
                  onChange={canton => setCanton(canton)}
                />
              </Grid>
              <Grid item xs={4}>
                <h4>Movimiento</h4>
                <Field
                  name="movimiento"
                  data={movimientos}
                  component={PlnDropdown}
                  onChange={movimiento =>
                    setMovimiento(movimiento)
                  }
                />
              </Grid>
            </Grid>
          </Formik>
        </div>
      );
    } else if (
      typeForm === 'papeletas-movimientos-provinciales'
    ) {
      formPage = (
        <div className={classes.boxForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={downloadForm}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <h4>Provincia</h4>
                <Field
                  name="provincia"
                  data={provincias}
                  component={PlnDropdown}
                  onChange={provincia =>
                    setProvincia(provincia)
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <h4>Movimiento</h4>
                <Field
                  name="movimiento"
                  data={movimientos}
                  component={PlnDropdown}
                  onChange={movimiento =>
                    setMovimiento(movimiento)
                  }
                />
              </Grid>
            </Grid>
          </Formik>
        </div>
      );
    } else if (
      typeForm === 'papeletas-movimientos-nacionales'
    ) {
      formPage = (
        <div className={classes.boxForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={downloadForm}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <h4>Movimiento</h4>
                <Field
                  name="movimiento"
                  data={movimientos}
                  component={PlnDropdown}
                  onChange={movimiento =>
                    setMovimiento(movimiento)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <h4>Puesto</h4>
                <Field
                  name="puesto"
                  data={puestos}
                  component={PlnDropdown}
                  onChange={puesto => setPuesto(puesto)}
                />
              </Grid>
            </Grid>
          </Formik>
        </div>
      );
    } else if (typeForm === 'papeletas-sectores') {
      formPage = (
        <div className={classes.boxForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={downloadForm}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <h4>Sectores</h4>
                <Field
                  name="sector"
                  data={sectores}
                  component={PlnDropdown}
                  onChange={sector => setSector(sector)}
                />
              </Grid>
            </Grid>
          </Formik>
        </div>
      );
    } else {
      formPage = '';
    }
    return formPage;
  };

  if (!Lodash.isEmpty(provincias)) {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <h3>FORMULARIO DE {getTitlePage()}</h3>
        <br />
        {typeForm === 'presidente-sectores' ||
        typeForm === 'hoja-informacion' ? (
          ''
        ) : (
          <h4 className={classes.subtitle}>
            Seleccione la información requerida:
          </h4>
        )}
        {typeForm === 'presidente-sectores' ||
        typeForm === 'hoja-informacion' ? (
          <div className={classes.boxDocToPdf}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'left' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    typeForm === 'hoja-informacion'
                      ? generateHojaInformacion
                      : generatePDF
                  }
                  style={{ marginTop: 10 }}
                >
                  DESCARGAR FORMULARIO
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          ''
        )}

        {renderForm()}

        {showPreview ? (
          <div className={classes.boxDocToPdf}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'left' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generatePDF}
                  style={{ marginTop: 10 }}
                >
                  DESCARGAR FORMULARIO
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          ''
        )}
      </Container>
    );
  }
  return '';
};

Formulario.propTypes = {};

export default withStyles(style)(Formulario);
